import { MediaType } from "../models/data-models";

export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not relevant
  } catch (e) {
    return true;
  }
}

export function getTopLocation(): string {
  if (window.top?.location !== window.location) {
    return document.referrer;
  }
  return window.location.href;
}

export function getHostName(url: string): string {
  const href = url || window.location.href;
  const host = new URL(href).hostname;
  return host.replace(/^www\./, "");
}

export function getTopLocationHost(): string {
  return getHostName(getTopLocation());
}

/**
 * Using new URLSearchParams(source) will automatically encode values,
 * but result is different from encodeURIComponent.
 * For example "a b" will result in
 * "a+b" using URLSearchParams
 * "a%20b" using encodeURIComponent
 * encodeURIComponent result is likely normally what is expected.
 */

export function queryStringToObject(source: string = ""): object {
  return source
    .replace(/^[^?]*\?/, "")
    .split("&")
    .filter((pair) => pair.length > 0)
    .reduce((result, pair) => {
      const [key, value] = pair.split("=");
      if (key.length > 0) {
        result[key] =
          value === undefined ? undefined : decodeURIComponent(value);
      }
      return result;
    }, {});
}

export function queryStringFromObject(source: object): string {
  return Object.entries(source)
    .map(([key, value]) => {
      let pair = encodeURIComponent(key);
      if (value !== undefined) {
        pair += "=" + encodeURIComponent(value);
      }
      return pair;
    })
    .join("&");
}

export function resolveMediaType(url: string): MediaType {
  const playbackURL = new URL(url);
  const extension = playbackURL.pathname.split(".").pop();
  switch (extension) {
    case "m3u8":
      return MediaType.HLS;
    case "mpd":
      return MediaType.DASH;
  }
  return MediaType.MP4;
}
