import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { fontSizes } from "./font-sizes";
import { fontWeights } from "./font-weights";
import { fonts } from "./fonts";
import { lineHeights } from "./line-heights";
import { link } from "./link";
import { margins } from "./margins";
import { radii } from "./radii";
import { space } from "./space";
import { text } from "./text";
export const theme = {
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    link,
    radii,
    space,
    text,
    margins,
};
