import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useClientCallback } from "../providers/ClientCallbackContext";
import { useControls, usePlayerState } from "../providers/CoreProvider";
const PlaybackRateSelectorWrapper = styled.div `
  position: absolute;
  border-radius: 16px;
  width: 100%;
  max-width: 300px;
  cursor: default;
  pointer-events: all;

  bottom: 16px;
  padding: 16px 16px 24px;
  right: 0;
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.25);

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
    
    ${({ isMobile }) => isMobile &&
    `
      bottom: 5em;
      padding-top: 8px;
      padding-bottom: 16px;
      right: auto;
    `}}
`;
const PlaybackHeader = styled.div `
  font-size: 16px;
  margin: 8px;
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
`;
const PlaybackRateValues = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
`;
const PlaybackRate = styled.button `
  outline: none;
  background: none;
  border: none;
  padding: 4px 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.52);
`;
const PlaybackRateSelected = styled(PlaybackRate) `
  color: #000;
  cursor: default;
  background: #fff;
  border-radius: 6px;
`;
const PLAYBACK_RATES = [0.5, 1, 1.25, 1.75, 2];
export const PlaybackRateSelector = ({ onSelected, isMobile }) => {
    const controls = useControls();
    const { onUserSelectedPlaybackRate } = useClientCallback();
    const { playbackRate } = usePlayerState();
    return (_jsxs(PlaybackRateSelectorWrapper, { isMobile: isMobile, onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
        }, children: [_jsx(PlaybackHeader, { children: "PLAYBACK SPEED" }), _jsx(PlaybackRateValues, { children: PLAYBACK_RATES.map((num) => {
                    if (num === playbackRate) {
                        return (_jsxs(PlaybackRateSelected, { onClick: () => {
                                onSelected();
                            }, children: [num, "x"] }, num));
                    }
                    else {
                        return (_jsxs(PlaybackRate, { onClick: () => {
                                controls?.setPlaybackRate(num);
                                if (onUserSelectedPlaybackRate) {
                                    onUserSelectedPlaybackRate(num);
                                }
                                onSelected();
                            }, children: [num, "x"] }, num));
                    }
                }) })] }));
};
