/**
 * Random values and uuid,
 * using crypo when possible,
 * or falling back to random.
 *
 * There are faster ways to
 * handle this, but they are
 * not very readable, and
 * speed should not be a very
 * large concern for usage at
 * this point.
 */

function getRandomHexValue() {
  let value: number;
  try {
    value = crypto.getRandomValues(new Uint8Array(1))[0] % 16;
  } catch (_e) {
    value = Math.floor(Math.random() * 16);
  }
  return value.toString(16);
}

export function getRandomHexString(length: number = 0) {
  if (length > 0) {
    let value: string = "";
    while (length--) {
      value += getRandomHexValue();
    }
    return value;
  } else {
    throw new Error("Unexpected length value in getRandomHexValue: " + length);
  }
}

/**
 * Returns standard compliant v4 uuid to match
 * the result of crypto.randomUUID.
 * UUID v4 format is
 * "00000000-0000-x000-y000-000000000000"
 * where x is 4 to indicate version 4,
 * and y is 8-b to indicate current standard.
 */
function createV4Uuid(): string {
  const version: string = "4";
  const variant: string = (
    8 +
    (parseInt(getRandomHexValue(), 16) % 4)
  ).toString(16);

  return [
    getRandomHexString(8), // 00000000
    getRandomHexString(4), // 0000
    version + getRandomHexString(3), // x000
    variant + getRandomHexString(3), // y000
    getRandomHexString(12), // 000000000000
  ].join("-"); // 00000000-0000-x000-y000-000000000000
}

// example: "adf773b1-a340-4434-bab3-db7546a7709f"
function v4uuid(): string {
  try {
    return window.crypto.randomUUID();
  } catch (_e) {
    return createV4Uuid();
  }
}

export { v4uuid as uuid };
