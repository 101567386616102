/**
 * Constants related to text and audio tracks
 */

import { AWPTextTrack, AWPTextTrackKind } from "../models/track-models";

export const NO_TEXT_TRACK: AWPTextTrack = {
  id: "-1",
  kind: AWPTextTrackKind.NOTHING,
  label: "off", // fallback value not intended to be displayed on screen
  language: "",
};
