import mitt, { Emitter } from "mitt";

/**
 * BaseEventEmitter, fully typed if providing T as an interface eg.
 * interface Events {
 *   [EventEnum.Event]: { eventData here }
 * }`
 */
export class EventEmitter<T> {
  private emitter?: Emitter<Record<keyof T, any>>;

  constructor() {
    this.emitter = mitt();
  }

  protected emit<K extends keyof T>(eventType: K, data: T[K]) {
    return this.emitter?.emit(eventType, data);
  }

  public on<K extends keyof T>(eventType: K, handler: (data: T[K]) => void) {
    return this.emitter?.on(eventType, handler);
  }
  public off<K extends keyof T>(eventType: K, handler?: (data: T[K]) => void) {
    return this.emitter?.off(eventType, handler);
  }

  public onAll(handler: (eventType: keyof T, data: T[keyof T]) => void) {
    return this.emitter?.on("*", handler);
  }
  public offAll(handler: (eventType: keyof T, data: T[keyof T]) => void) {
    return this.emitter?.off("*", handler);
  }

  public once<K extends keyof T>(eventType: K, handler: (data: T[K]) => void) {
    const onceHandler = (data) => {
      handler(data);
      this.emitter?.off(eventType, onceHandler);
    };
    return this.emitter?.on(eventType, onceHandler);
  }

  public destroy() {
    this.emitter?.all.clear();
    this.emitter = undefined;
  }
}
