import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdInsertionType, AdTrackingEvent, AdVideoVariant, } from "@tv4/avod-web-player-common";
import ArrowRightSvg from "../../icons/ArrowRight.svg";
import { useCurrentAdBreak, usePlayerState, } from "../../providers/CoreProvider";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { useIsClip } from "../../providers/MetadataProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import { AdBreakOverlayContainer, AdCount, VisitAdvertiserButton, } from "./AdBreakOverlay.styles";
const trackEvent = (event, ad) => {
    if (!ad || !event)
        return;
    let trackingUrls = [];
    switch (event) {
        case AdTrackingEvent.IMPRESSION:
            trackingUrls = ad.impressionUrlTemplates || [];
            break;
        case AdTrackingEvent.CLICK_THROUGH: {
            const clickThroughEvents = ad.creative.trackingEvents.clickThrough;
            if (Array.isArray(clickThroughEvents)) {
                clickThroughEvents.forEach((e) => {
                    if (typeof e === "string") {
                        trackingUrls.push(e);
                    }
                    else {
                        trackingUrls.push(e.url);
                    }
                });
            }
            if (ad.creative.clickThroughUrlTemplate?.url) {
                trackingUrls?.push(ad.creative.clickThroughUrlTemplate.url);
            }
            break;
        }
        case AdTrackingEvent.ERROR:
            trackingUrls = ad.errorUrlTemplates || [];
            break;
        case AdTrackingEvent.CLOSE:
            ad.creative.trackingEvents.close?.forEach((url) => {
                if (url) {
                    trackingUrls.push(url);
                }
            });
            break;
    }
    trackingUrls.forEach(async (url) => {
        new Image().src = url;
    });
};
const AdBreakOverlay = ({ visible, makeSpaceForBackButton, onAdButtonClick, }) => {
    const { isAd } = usePlayerState();
    const { currentAdBreak, currentAd } = useCurrentAdBreak();
    /*
    // The commented out code blocks/added code below marked with ! are in order to temporarily disable the ad label for all non-clips,
    // and temporarily disable the ad counter for all ad breaks
    const [adBreakTotalAds, setAdBreakTotalAds] = useState(0);
    const [adSequenceIndex, setAdSequenceIndex] = useState(0);
    */
    const size = useLayoutSize();
    const translations = useTranslations();
    /*
    // ! Uncomment if reverting to the original ad counter code
    useEffect(() => {
      setAdBreakTotalAds(
        currentAdBreak?.ads.filter((ad) => ad?.variant === AdVideoVariant.NORMAL)
          .length || 0
      );
      setAdSequenceIndex(0);
    }, [currentAdBreak]);
  
    useEffect(() => {
      if (currentAd?.variant !== AdVideoVariant.NORMAL) return;
      setAdSequenceIndex((prev) => prev + 1);
    }, [currentAd]);
  */
    const openAdvertiserLink = () => {
        if (!currentAd?.creative.clickThroughUrlTemplate)
            return;
        window.open(currentAd.creative.clickThroughUrlTemplate.url, "_blank");
        trackEvent(AdTrackingEvent.CLICK_THROUGH, currentAd);
        onAdButtonClick(currentAd.creative.clickThroughUrlTemplate.url);
    };
    const showAdIndicator = useIsClip() && visible && isAd; // ! Temporarily only show ad indicator for clips, remove if uncommented code below is used again
    const isLinearAd = currentAdBreak?.insertionType === AdInsertionType.Linear;
    const showAdLabel = isLinearAd ||
        (visible && isAd && currentAd?.variant === AdVideoVariant.NORMAL);
    const isSponsorAd = visible && isAd && currentAd?.variant === AdVideoVariant.SPONSOR;
    if (!showAdLabel && !isSponsorAd)
        return null;
    return (_jsxs(AdBreakOverlayContainer, { size: size, children: [!isSponsorAd && showAdIndicator && (_jsx(AdCount, { makeSpaceForBackButton: makeSpaceForBackButton, children: translations("ad_indicator__ad") })), currentAd?.creative.clickThroughUrlTemplate &&
                currentAd?.creative.clickThroughUrlTemplate.url !== "" && (_jsxs(VisitAdvertiserButton, { onClick: openAdvertiserLink, size: size, children: [translations("ad_break_overlay__visit_advertiser"), _jsx(ArrowRightSvg, {})] }))] }));
};
export { AdBreakOverlay };
