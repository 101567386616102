import { CoreEvents, DEFAULT_PLAYER_CONFIG, PlaybackApiAssetType, PlaybackState, } from "@tv4/avod-web-player-common";
import hotkeys from "hotkeys-js";
var KeyboardBindings;
(function (KeyboardBindings) {
    KeyboardBindings["TOGGLE_PLAY"] = "space";
    KeyboardBindings["FORWARD"] = "right";
    KeyboardBindings["BACKWARD"] = "left";
    KeyboardBindings["VOLUME_UP"] = "up";
    KeyboardBindings["VOLUME_DOWN"] = "down";
    KeyboardBindings["TOGGLE_MUTE"] = "m";
    KeyboardBindings["TOGGLE_FULLSCREEN"] = "f";
    KeyboardBindings["DEBUG_OVERLAY"] = "ctrl+alt+d";
})(KeyboardBindings || (KeyboardBindings = {}));
const SEEK_AMOUNT = DEFAULT_PLAYER_CONFIG.seekAmount;
const VOLUME_STEP = 0.1;
export class KeyboardShortcuts {
    playerCore;
    asset;
    playerControls;
    playerState;
    onPlayWhenEnded;
    shortcuts;
    constructor({ playerCore, onPlayWhenEnded, asset, }) {
        this.playerCore = playerCore;
        this.asset = asset;
        this.shortcuts = [
            KeyboardBindings.TOGGLE_PLAY,
            KeyboardBindings.TOGGLE_FULLSCREEN,
            KeyboardBindings.TOGGLE_MUTE,
            KeyboardBindings.VOLUME_UP,
            KeyboardBindings.VOLUME_DOWN,
            KeyboardBindings.FORWARD,
            KeyboardBindings.BACKWARD,
            KeyboardBindings.DEBUG_OVERLAY,
        ];
        this.onPlayWhenEnded = onPlayWhenEnded;
        this.setupKeyboardControls();
        this.setupStateListener();
        this.setupKeyListeners();
    }
    setupKeyboardControls() {
        this.playerCore.on(CoreEvents.LOADED_PLAYBACK, () => {
            this.playerControls = this.playerCore.getControls();
        });
        this.playerCore.on(CoreEvents.CHROMECAST_CONNECTED, () => {
            this.playerControls = this.playerCore
                .getChromeCastManager()
                ?.getControls();
        });
        this.playerCore.on(CoreEvents.CHROMECAST_DISCONNECTED, () => {
            this.playerControls = this.playerCore.getControls();
        });
    }
    setupStateListener() {
        this.playerCore.on(CoreEvents.STATE_CHANGED, ({ state }) => {
            this.playerState = state;
        });
    }
    setupKeyListeners() {
        hotkeys(this.shortcuts.join(","), this.onShortcutPressed.bind(this));
    }
    updateKeyboardShortcutsOptions(keyboardShortcutsOptions) {
        this.asset = keyboardShortcutsOptions.asset ?? this.asset;
    }
    isSeekAllowed() {
        return (!this.playerState?.isPauseAd &&
            !this.playerState?.isAd &&
            this.asset?.playbackApiAssetType !== PlaybackApiAssetType.CHANNEL);
    }
    onShortcutPressed(e, handler) {
        if (!this.playerState)
            return;
        switch (handler.key) {
            case KeyboardBindings.TOGGLE_PLAY:
                this.blockDefault(e);
                if (this.playerState?.isLive)
                    return;
                if (this.playerState?.playbackState === PlaybackState.PLAYING) {
                    this.playerControls?.pause?.();
                }
                else if (this.playerState?.playbackState === PlaybackState.ENDED) {
                    this.onPlayWhenEnded?.();
                }
                else {
                    this.playerControls?.play?.();
                }
                break;
            case KeyboardBindings.FORWARD:
                this.blockDefault(e);
                if (!this.isSeekAllowed() || !this.playerState?.currentTime)
                    return;
                this.playerControls?.seekForward?.(SEEK_AMOUNT);
                break;
            case KeyboardBindings.BACKWARD:
                this.blockDefault(e);
                if (!this.isSeekAllowed() || !this.playerState?.currentTime)
                    return;
                this.playerControls?.seekBackward?.(SEEK_AMOUNT);
                break;
            case KeyboardBindings.TOGGLE_MUTE:
                this.blockDefault(e);
                this.playerControls?.toggleMute?.();
                break;
            case KeyboardBindings.VOLUME_DOWN:
                this.blockDefault(e);
                this.playerControls?.setVolume?.(this.playerState.volume - VOLUME_STEP);
                break;
            case KeyboardBindings.VOLUME_UP:
                this.blockDefault(e);
                this.playerControls?.setVolume?.(this.playerState.volume + VOLUME_STEP);
                break;
            case KeyboardBindings.TOGGLE_FULLSCREEN:
                this.playerControls?.toggleFullscreen?.();
                break;
            case KeyboardBindings.DEBUG_OVERLAY:
                window.dispatchEvent(new CustomEvent("showDebugOverlay"));
                break;
            default:
                break;
        }
    }
    blockDefault(e) {
        e.preventDefault();
    }
    destroy() {
        this.shortcuts.forEach((shortcut) => hotkeys.unbind(shortcut));
    }
}
