import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { API_ERROR, PLAYER_ERROR } from "@tv4/avod-web-player-common";
import { useState } from "react";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import { LayoutSize } from "../../util/device";
import RefreshPageButton from "../RefreshPageButton";
import { ActiveStream, DevCode, ErrorButton, Message, StreamingDevice, StreamingLocation, StreamingTitle, Title, Wrapper, } from "./ErrorMessage.styles";
const StreamingDetails = ({ streamsArray, isSmallLayout, }) => {
    const translations = useTranslations();
    return (_jsxs(_Fragment, { children: [streamsArray.map((activeStream, index) => (_jsxs(ActiveStream, { children: [_jsxs(StreamingDevice, { variant: isSmallLayout ? "body3Strong" : "body1Strong", children: [activeStream.device, " -"] }), _jsx(StreamingTitle, { variant: isSmallLayout ? "body4" : "body3", children: activeStream.title }), _jsxs(StreamingLocation, { variant: isSmallLayout ? "body4" : "body3", children: ["(", activeStream.city, ")"] })] }, index))), _jsx(Message, { variant: isSmallLayout ? "body4" : "body3", children: isSmallLayout
                    ? translations("error_message__stream_limit__button_details_log_out_instructions_short")
                    : translations("error_message__stream_limit__button_details_log_out_instructions") })] }));
};
const getUniqueDeviceOsCityCombinations = (activeStreams) => {
    const uniqueDeviceOsCityCombinations = [];
    const uniqueCombinations = new Set();
    activeStreams.forEach((activeStream) => {
        const combination = activeStream.device + "-" + activeStream.os + "-" + activeStream.city;
        if (!uniqueCombinations.has(combination)) {
            uniqueCombinations.add(combination);
            uniqueDeviceOsCityCombinations.push(activeStream);
        }
    });
    return uniqueDeviceOsCityCombinations;
};
export const ErrorMessage = ({ error, onExitClick, }) => {
    const [showActiveStreamDetails, setShowActiveStreamDetails] = useState(false);
    const translations = useTranslations();
    const layoutSize = useLayoutSize();
    const isSmallLayout = [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize);
    const activeStreams = error?.raw?.details?.active_streams || [];
    const uniqueDeviceOsCityCombinations = getUniqueDeviceOsCityCombinations(activeStreams);
    const getHeaderText = () => {
        switch (error?.code) {
            case PLAYER_ERROR.AD_BLOCKER:
                return translations("error_message__ad_blocker__header");
            case API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION:
                if (showActiveStreamDetails)
                    translations("error_message__stream_limit_details__header_playing_now");
                return translations("error_message__stream_limit_single_stream__header", {
                    eventTitle: activeStreams[0]?.title ??
                        translations("error_message__stream_limit_single_stream__header_this_stream"),
                });
            case API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED:
                if (showActiveStreamDetails)
                    return translations("error_message__stream_limit_details__header_playing_now");
                return translations("error_message__stream_limit_stream_quota__header");
            default:
                return translations("error_message__problem_general");
        }
    };
    const getMessageText = () => {
        switch (error?.code) {
            case PLAYER_ERROR.AD_BLOCKER:
                return translations("error_message__ad_blocker__message");
            case API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION:
                return isSmallLayout
                    ? translations("error_message__stream_limit_single_stream__message_shortened")
                    : translations("error_message__stream_limit_single_stream__message");
            case API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED:
                return isSmallLayout
                    ? translations("error_message__stream_limit_stream_quota__message_shortened")
                    : translations("error_message__stream_limit_stream_quota__message");
            default:
                return error?.message;
        }
    };
    return (_jsxs(Wrapper, { children: [_jsx(Title, { variant: isSmallLayout ? "title3" : "title2", children: getHeaderText() }), !!error?.message && (_jsxs(_Fragment, { children: [showActiveStreamDetails ? (_jsx(StreamingDetails, { streamsArray: uniqueDeviceOsCityCombinations, isSmallLayout: isSmallLayout })) : (_jsx(Message, { variant: isSmallLayout ? "body3Strong" : "body1Strong", children: getMessageText() })), error.code == PLAYER_ERROR.AD_BLOCKER && (_jsx(RefreshPageButton, { refreshPageHandler: () => location.reload() })), (error.code == API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION ||
                        error.code == API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED) && (_jsxs(_Fragment, { children: [_jsx(ErrorButton, { onClick: showActiveStreamDetails
                                    ? () => setShowActiveStreamDetails(false)
                                    : onExitClick, layoutSize: layoutSize, close: true, children: showActiveStreamDetails
                                    ? translations("error_message__stream_limit__button_back")
                                    : translations("error_message__stream_limit__button_close") }), !showActiveStreamDetails && (_jsx(ErrorButton, { onClick: () => setShowActiveStreamDetails(true), layoutSize: layoutSize, children: translations("error_message__stream_limit__button_details_about_streaming") }))] })), _jsx(DevCode, { children: btoa(error.message) })] }))] }));
};
export default ErrorMessage;
