import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { defaultTextSizes, HTMLTextTrackSize, localPreferences, } from "@tv4/avod-web-player-common";
import { useState } from "react";
import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import { TextSizeOptions, TextTrackSize, TrackListContainer, TrackTitle, } from "./TracksMenu/TracksMenu.styles";
const getPreviewSizeByFullSize = (size) => {
    const previewSize = Object.values(defaultTextSizes).find((textSize) => textSize.size === size)?.previewSize;
    return previewSize || defaultTextSizes[HTMLTextTrackSize.LARGE].previewSize;
};
const TextTrackControls = ({ $stackVertically, }) => {
    const { textTrackSettings: { textSize }, } = usePlayerState();
    const translations = useTranslations();
    const controls = useControls();
    const [activeTextSize, setActiveTextSize] = useState(getPreviewSizeByFullSize(textSize));
    const setTextTrackSize = (fontSize) => {
        controls?.setSubtitleTextSize?.(fontSize);
    };
    return (_jsxs(TrackListContainer, { children: [_jsx(TrackTitle, { "$stackVertically": $stackVertically, children: translations("language__subtitles_size") }), _jsx(TextSizeOptions, { children: Object.entries(defaultTextSizes).map(([htmlTextTrackSize, { size, previewSize, previewLineHeight }]) => (_jsx(TextTrackSize, { "$size": previewSize, "$lineHeight": previewLineHeight, "$active": activeTextSize === previewSize, onClick: () => {
                        setActiveTextSize(previewSize);
                        setTextTrackSize(size);
                        localPreferences.setPreferredTextSize(htmlTextTrackSize);
                    }, children: "Abc" }, htmlTextTrackSize))) })] }));
};
export default TextTrackControls;
