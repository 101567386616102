// Playwright workaround
export const getWindow = (): Window | null => {
  return typeof window !== "undefined" ? window : null;
};

// TODO: Stop relying on deprecated browser APIs
// Other than userAgent, these APIs are all deprecated, but still well supported, and without substitutes,
// (navigator.userAgentData.platform is a substitute for platform, but it behaves differently and isn't supported on Firefox and old Chromium)
// https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform

export function getUserAgent(): string {
  return getWindow()?.navigator?.userAgent?.toLowerCase?.() || "";
}

export function getVendor(): string {
  return getWindow()?.navigator?.vendor?.toLowerCase?.() || "";
}

export function getPlatform(): string {
  return getWindow()?.navigator?.platform?.toLowerCase?.() || "";
}

export function getAppVersion(): string {
  return getWindow()?.navigator?.appVersion || "";
}
