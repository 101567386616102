import { jsx as _jsx } from "react/jsx-runtime";
import { DEFAULT_PLAYER_STATE, PlaybackMode, } from "@tv4/avod-web-player-common";
import { streamInfoService } from "@tv4/avod-web-player-http";
import { createContext, useContext, useEffect, useState, } from "react";
const CoreContext = createContext({
    playerState: DEFAULT_PLAYER_STATE,
    controls: null,
    adBreaks: [],
    currentAdBreak: undefined,
    currentAd: undefined,
    options: undefined,
    chromeCastManager: undefined,
});
export function CoreProvider({ playerState, controls, adBreaks, currentAdBreak, currentAd, options, chromeCastManager, getCurrentState, getDebugInfo, children, }) {
    return (_jsx(CoreContext.Provider, { value: {
            playerState,
            controls,
            adBreaks,
            currentAdBreak,
            currentAd,
            options,
            chromeCastManager,
            getCurrentState,
            getDebugInfo,
        }, children: children }));
}
export const usePlayerState = () => {
    const { playerState } = useContext(CoreContext);
    return playerState;
};
export const usePlayerStatePolling = (pollingInterval = 250) => {
    const { playerState, getCurrentState } = useContext(CoreContext);
    const [state, setState] = useState(playerState);
    useEffect(() => {
        const interval = window.setInterval(() => {
            if (getCurrentState) {
                setState(getCurrentState());
            }
        }, pollingInterval);
        return () => clearInterval(interval);
    });
    return state;
};
export const useControls = () => {
    const { controls } = useContext(CoreContext);
    return controls;
};
export const useAdBreaks = () => {
    const { adBreaks, playerState } = useContext(CoreContext);
    if (playerState.playbackMode !== PlaybackMode.DEFAULT) {
        return streamInfoService.adBreaks;
    }
    return adBreaks;
};
export const useCurrentAdBreak = () => {
    const { currentAdBreak, currentAd } = useContext(CoreContext);
    return { currentAdBreak, currentAd };
};
export const useChromecastManager = () => {
    const { chromeCastManager } = useContext(CoreContext);
    return chromeCastManager;
};
export const useOptions = () => {
    const { options } = useContext(CoreContext);
    return options;
};
export const useDebugInfo = () => {
    const { getDebugInfo } = useContext(CoreContext);
    return getDebugInfo && getDebugInfo();
};
