import { Service } from "..";
import { getTopLocation } from "./url";

export let isFotbollskanalenCached: boolean;
export let isKoketCached: boolean;
export let isTv4SeCached: boolean;
export let isTv4PlayCached: boolean;
export let isMtvCached: boolean;
export let isMtvUutisetCached: boolean;
export let isMtvUutisetPwaCached: boolean;

export const isTv4Se = (): boolean => {
  if (isTv4SeCached !== undefined) return isTv4SeCached;

  isTv4SeCached = getTopLocation().toLowerCase().includes("tv4.se");

  return isTv4SeCached;
};

export const isFotbollskanalen = (): boolean => {
  if (isFotbollskanalenCached !== undefined) return isFotbollskanalenCached;

  isFotbollskanalenCached = getTopLocation()
    .toLowerCase()
    .includes("fotbollskanalen.se");

  return isFotbollskanalenCached;
};

export const isKoket = (): boolean => {
  if (isKoketCached !== undefined) return isKoketCached;

  isKoketCached = getTopLocation().toLowerCase().includes("koket.se");

  return isKoketCached;
};

export const isMtv = (): boolean => {
  if (isMtvCached !== undefined) return isMtvCached;

  isMtvCached =
    /mtv.fi|mtv-stage.fi|localhost:1234\/mtv|nordic-web-player.a2d.tv\/mtv/.test(
      getTopLocation().toLowerCase()
    );

  return isMtvCached;
};

export const isMtvUutiset = (): boolean => {
  if (isMtvUutisetCached !== undefined) return isMtvUutisetCached;

  isMtvUutisetCached = getTopLocation().toLowerCase().includes("mtvuutiset.fi");

  return isMtvUutisetCached;
};

export const isMtvUutisetPwa = (): boolean => {
  if (isMtvUutisetPwaCached !== undefined) return isMtvUutisetPwaCached;
  isMtvUutisetPwaCached = /app.mtvuutiset.fi|app-test.mtvuutiset.fi/.test(
    getTopLocation().toLowerCase()
  );

  return isMtvUutisetPwaCached;
};

export const isTv4Play = (): boolean => {
  if (isTv4PlayCached !== undefined) return isTv4PlayCached;

  isTv4PlayCached =
    !isFotbollskanalen() &&
    !isKoket() &&
    !isTv4Se() &&
    !isMtv() &&
    !isMtvUutiset();

  return isTv4PlayCached;
};

export const isMtvOrUutisetService = (service: Service): boolean => {
  return service === Service.MTV || service === Service.MTVUUTISET;
};
