import { Service } from "../index";

export const DISABLED_TEXT: string = "disabled";

function getAvailableLanguage(
  languages: string[] | string | undefined | null,
  available: string[]
): string | undefined {
  if (!Array.isArray(languages)) {
    languages = languages ? [languages] : [];
  }
  return languages.find((language: string) => available.includes(language));
}

// get the hard of hearing text track or default to normal text track for current language, if preferenceLanguage is set to hard of hearing
function getHardOfHearingLanguage(
  service: string,
  preferenceLanguage: string | null,
  available: string[]
): string | undefined {
  if (preferenceLanguage) {
    const hardOfHearingLanguageCodes: string[] = ["qag"];

    let defaultLanguage: string = "sv";

    if (service === Service.MTV) {
      // "nl" is legacy hard of hearing for MTV
      hardOfHearingLanguageCodes.push("nl");
      defaultLanguage = "fi";
    }

    // user preference is a hard of hearing language code
    if (hardOfHearingLanguageCodes.includes(preferenceLanguage)) {
      // fallback to default language if hard of hearing is not available
      hardOfHearingLanguageCodes.push(defaultLanguage);
      // put preferenceLanguage first so the same code is prioritized if available
      hardOfHearingLanguageCodes.unshift(preferenceLanguage);
      // return the first hard of hearing language code found from available
      return getAvailableLanguage(hardOfHearingLanguageCodes, available);
    }
  }
}

// will return text track for current service language if the current audio language does not match
function getDefaultTextLanguage(
  service: string,
  audioLanguage: string | null = null
): string | undefined {
  const defaultLanguage = service === Service.MTV ? "fi" : "sv";

  // current audio language does not match service language - display subtitles by default
  if (audioLanguage !== defaultLanguage) {
    return defaultLanguage;
  }
}

export function getInitialTextLanguage(
  service: string,
  available: string[],
  audioLanguage: string | null = null,
  preferenceLanguage: string | null = null
): string {
  let language = DISABLED_TEXT;

  if (preferenceLanguage !== DISABLED_TEXT) {
    language =
      getHardOfHearingLanguage(service, preferenceLanguage, available) ||
      getAvailableLanguage(preferenceLanguage, available) ||
      getAvailableLanguage(
        getDefaultTextLanguage(service, audioLanguage),
        available
      ) ||
      language;
  }

  return language;
}

export function getInitialAudioLanguage(
  service: string,
  available: string[],
  preferenceLanguage: string | null = null
): string | null {
  return (
    getAvailableLanguage(preferenceLanguage, available) ||
    getAvailableLanguage(service === Service.MTV ? "fi" : "sv", available) ||
    null
  );
}
