import { jsx as _jsx } from "react/jsx-runtime";
import { getTranslationsForLocale, localeFromService, } from "@tv4/avod-web-player-common";
import { createContext, useContext } from "react";
const LocaleContext = createContext("sv");
export function TranslationsProvider({ service, children, }) {
    const locale = localeFromService(service);
    return (_jsx(LocaleContext.Provider, { value: locale, children: children }));
}
export const useTranslations = () => {
    const locale = useContext(LocaleContext);
    return getTranslationsForLocale(locale);
};
