import { AdServerOptions } from "./ads-models";
import {
  ContentRating,
  FairplayLicense,
  License,
  PlaybackApiAssetType,
} from "./data-models";
import {
  FacebookTrackingMetadata,
  GATrackingMetadata,
  LinkPulseTrackingMetadata,
  NielsenTrackingMetadata,
  NpawTrackingMetadata,
} from "./tracking-metadata-models";

export interface AssetMarker {
  categoryId: number;
  categoryName: string;
  timeBegin: number;
  timeEnd: number;
  title: string;
}

export enum UserTier {
  AVOD = "AVOD",
  HVOD = "HVOD",
  SVOD = "SVOD",
}

export type AssetDataResponse = {
  bumpers: {
    start: string;
    lowresStart: string;
    end: string;
    prerollEnd: string;
  };
  metadata: {
    id: string;
    title: string;
    description: string;
    programNid: string;
    seriesTitle: string;
    episodeTitle: string;
    seasonNumber: number;
    episodeNumber: number;
    categories: string[];
    isClip: boolean;
    isLive: boolean;
    isDrmProtected: boolean;
    duration: number;
    image?: string;
    type?: PlaybackApiAssetType;
    hideAds: boolean;
    startOver?: boolean;
  };
  items: AssetMarker[];
  mediaUri: string;
  trackingData: {
    videoplaza: AdServerOptions;
    linkPulse: LinkPulseTrackingMetadata;
    nielsen: NielsenTrackingMetadata;
    youbora: NpawTrackingMetadata;
    GA: GATrackingMetadata;
    facebook: FacebookTrackingMetadata;
  };
  contentRating: ContentRating;
};

export interface Thumbnail {
  type: "tv" | "desktop" | "tablet" | "mobile";
  width: number;
  height: number;
  url: string;
}

export type Orientation = "landscape" | "portrait";

// PlayResponse is the same as the AssetDataResponse but with these two additional props
export type PlayResponse = AssetDataResponse & {
  playbackItem: {
    type: "dash" | "hls";
    manifestUrl: string;
    startoverManifestUrl: string;
    streaminfoUrl: string;
    originUrl?: string;
    accessUrl: string;
    isStitched: boolean;
    license?: License | FairplayLicense;
    thumbnails?: Thumbnail[];
    orientation?: Orientation;
  };
  userTier: UserTier;
};
