export const fontSizes = {
    0: 12,
    1: 14,
    2: 16,
    3: 18,
    4: 21,
    5: 23,
    6: 28,
    7: 35,
    8: 40,
    9: 56,
    10: 68,
    11: 80,
};
