/**
 * request and also trying to estimate bandwidth while making request.
 * bandwidth estimations cannot be expected to be accurate, and will
 * be less accurate for small requests.
 */

type ListenerType = string;
type ListenerCallback = (event: Event | ProgressEvent) => void;

type Listener = {
  type: ListenerType;
  callback: ListenerCallback;
};

export enum ExpectedType {
  xml,
  text,
  json,
}

const HEADERS_RECEIVED = 2;

export type TXHROptions = {
  timeout?: number;
  estimate?: boolean;
  get?: string;
  expectedType?: ExpectedType;
  expectedStatus?: number | Array<number>;
};

export class XHR extends XMLHttpRequest {
  private expectedType: ExpectedType | undefined;
  private expectedStatus: Array<number> = [200];

  private listeners: Array<Listener> = [];

  private bandwidthEstimation: number = 0;

  public get estimatedBandwidth(): number {
    return this.bandwidthEstimation;
  }

  constructor(options?: TXHROptions) {
    super();

    if (options?.timeout) {
      this.timeout = options.timeout;
    }

    if (options?.estimate !== false) {
      this.startEstimation();
    }

    if (options?.get) {
      this.open("get", options.get, true);
    }

    this.expectedType = options?.expectedType ?? undefined;
    this.expectedStatus =
      (options?.expectedStatus &&
        Array.prototype.concat.call([], options.expectedStatus)) ||
      this.expectedStatus;
  }

  public startEstimation(): void {
    // estimation should start before send is called
    if (this.readyState < HEADERS_RECEIVED) {
      let downloadStart: number = 0;

      const calculateEstimation = (loaded: number, downloadEnd: number) => {
        if (downloadStart !== 0) {
          const downloadTime = (downloadEnd - downloadStart) / 1000; // download time in seconds
          const downloadedBits = loaded * 8;
          const bandwidth = downloadedBits / downloadTime; // bits / second download speed
          const bandwidthKbps = bandwidth / 1000;

          this.bandwidthEstimation = bandwidthKbps || 0;
        }
      };

      const handleProgress: ListenerCallback = (e: Event): void => {
        const event = e as ProgressEvent;
        calculateEstimation(event.loaded, performance.now());
      };

      const startEstimation = () => {
        if (this.readyState === HEADERS_RECEIVED && downloadStart === 0) {
          // start measuring download time of content after headers have loaded.
          downloadStart = performance.now();
          this.removeEventListener("readystatechange", startEstimation);
        }
      };

      const abortEstimateBandwidth = (): void => {
        this.removeEventListener("progress", handleProgress);
        this.removeEventListener("readystatechange", startEstimation);
        this.removeEventListener("loadend", abortEstimateBandwidth);
        this.removeEventListener("timeout", abortEstimateBandwidth);
      };

      // completed, aborted or error
      this.addEventListener("loadend", abortEstimateBandwidth);
      this.addEventListener("timeout", abortEstimateBandwidth);

      this.addEventListener("readystatechange", startEstimation);

      this.addEventListener("progress", handleProgress);
    }
  }

  public override send<T = ReturnType<XHR["json"]>>(
    body?: string
  ): Promise<
    | void
    | ReturnType<XHR["json"]>
    | ReturnType<XHR["xml"]>
    | ReturnType<XHR["text"]>
  > {
    return new Promise((resolve, reject) => {
      const cleanup = () => {
        this.removeEventListener("loadend", handleLoaded);
        this.removeEventListener("timeout", handleTimeout);
      };

      const handleLoaded = () => {
        cleanup();
        if (this.expectedStatus.includes(this.status)) {
          switch (this.expectedType) {
            case ExpectedType.json:
              resolve(this.json<T>());
              break;
            case ExpectedType.xml:
              resolve(this.xml());
              break;
            case ExpectedType.text:
              resolve(this.text());
              break;
            default:
              resolve(undefined);
              break;
          }
        } else {
          reject(this.json() || this.response || this.responseText);
        }
      };

      const handleTimeout = () => {
        cleanup();
        reject();
      };

      this.addEventListener("loadend", handleLoaded);
      this.addEventListener("timeout", handleTimeout);

      super.send(body);
    });
  }

  public json<T = Record<string, any>>(): T | undefined {
    try {
      return JSON.parse(this.responseText) as T;
    } catch (_e) {
      // noop
    }
  }

  public text(): string | undefined {
    return this.responseText || undefined;
  }

  public xml(): Document | undefined {
    try {
      return (
        this.responseXML ||
        new DOMParser().parseFromString(this.responseText, "text/xml")
      );
    } catch (_e) {
      // noop
    }
  }

  private findListenerIndex(type: string, callback: ListenerCallback): number {
    return this.listeners.findIndex(
      (listener) => listener.type === type && listener.callback === callback
    );
  }

  public override addEventListener(
    type: string,
    callback: ListenerCallback
  ): void {
    const listenerIndex = this.findListenerIndex(type, callback);

    if (listenerIndex === -1) {
      this.listeners.push({
        type,
        callback,
      });
      return super.addEventListener(type, callback);
    }
  }

  public override removeEventListener(
    type: string,
    callback: ListenerCallback
  ): void {
    const listenerIndex = this.findListenerIndex(type, callback);

    if (listenerIndex !== -1) {
      this.listeners.splice(listenerIndex, 1);
      return super.removeEventListener(type, callback);
    }
  }

  public destroy() {
    let i = this.listeners.length;
    while (i--) {
      this.removeEventListener(
        this.listeners[i].type,
        this.listeners[i].callback
      );
    }
    this.listeners.length = 0;
    this.abort();
  }
}
