import {
  BROWSER_NAME,
  BROWSER_VERSION,
  CAN_CAST,
  CAN_PLAY_VIDEO,
  ENVIRONMENT_SUPPORTS_DOWNLINK,
  IS_ANDROID,
  IS_CHROME,
  IS_CHROME_OS,
  IS_CHROMECAST,
  IS_CHROMECAST_ULTRA,
  IS_FIREFOX,
  IS_IOS,
  IS_IPAD_OS,
  IS_LINUX,
  IS_MAC_OS,
  IS_MOBILE,
  IS_MS_EDGE,
  IS_MS_IE,
  IS_SAFARI,
  IS_SUPPORTED_OS,
  IS_WINDOWS,
  NATIVE_HLS_SUPPORTED,
  NATIVE_LIVE_HLS_SUPPORTED,
  OS_NAME,
  SUPPORTED_PROTOCOLS,
} from "./capabilities";
import { Browser, OperatingSystem } from "./enums";

// The device capabilities library is heavily cached due to browser APIs
// generally being slow. The original device capabilities (in BonnierPlayer,
// where this is copied from), pre-caching, added 500ms+ to video start time
// due to calling browser APIs hundreds, or thousands, of times. Everything
// was evaluated at call time instead of utilizing cached values.
//
// The reason everything is exposed as methods instead of a set of constants
// is to enable easy mocking of this module in unit tests.
//
// Cache is king.

const getOSName = (): OperatingSystem => OS_NAME;
const getBrowserName = (): Browser => BROWSER_NAME;
const getBrowserVersion = (): number => BROWSER_VERSION;
const getSupportedProtocols = (): string => SUPPORTED_PROTOCOLS;

const isWindows = (): boolean => IS_WINDOWS;
const isMacOS = (): boolean => IS_MAC_OS;
const isIOS = (): boolean => IS_IOS;
const isIpadOS = (): boolean => IS_IPAD_OS;
const isAndroid = (): boolean => IS_ANDROID;
const isChromeOS = (): boolean => IS_CHROME_OS;
const isLinux = (): boolean => IS_LINUX;

const isSupportedOS = (): boolean => IS_SUPPORTED_OS;

const isChromecast = (): boolean => IS_CHROMECAST;
const isChromecastUltra = (): boolean => IS_CHROMECAST_ULTRA;
const isMobile = (): boolean => IS_MOBILE;

const isChrome = (): boolean => IS_CHROME;
const isMsEdge = (): boolean => IS_MS_EDGE;
const isSafari = (): boolean => IS_SAFARI;
const isMsIE = (): boolean => IS_MS_IE;
const isFirefox = (): boolean => IS_FIREFOX;

const canCast = (): boolean => CAN_CAST;

const canPlayVideo = (): boolean => CAN_PLAY_VIDEO;
const isNativeHlsSupported = (live = false): boolean =>
  live ? NATIVE_LIVE_HLS_SUPPORTED : NATIVE_HLS_SUPPORTED;

interface NavigatorWithDownlink extends Navigator {
  connection: {
    downlink: number;
  };
}

// This type guard cheats in order to enable utilize cache.
// The guard could properly check that the navigator argument has
// downlink, but it's instead evaluated once on window and saved as
// a const.
const browserSupportsDownlink = (
  domNavigator: Navigator
): domNavigator is NavigatorWithDownlink => ENVIRONMENT_SUPPORTS_DOWNLINK;

export {
  Browser,
  browserSupportsDownlink,
  canCast,
  canPlayVideo,
  getBrowserName,
  getBrowserVersion,
  getOSName,
  getSupportedProtocols,
  isAndroid,
  isChrome,
  isChromecast,
  isChromecastUltra,
  isChromeOS,
  isFirefox,
  isIOS,
  isIpadOS,
  isLinux,
  isMacOS,
  isMobile,
  isMsEdge,
  isMsIE,
  isNativeHlsSupported,
  isSafari,
  isSupportedOS,
  isWindows,
  OperatingSystem,
};
