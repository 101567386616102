import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { clamp, PlayerMode } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import { useCallback, useRef } from "react";
import useDragging from "../../hooks/useDragging";
import VolumeIconSvg from "../../icons/VolumeIcon.svg";
import VolumeIconMutedSvg from "../../icons/VolumeIconMuted.svg";
import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useLayoutSettings, useLayoutSize, } from "../../providers/LayoutProvider";
import { LayoutSize } from "../../util/device";
import { VolumeButton, VolumeControllerWrapper, VolumeSliderActualProgress, VolumeSliderProgressBar, VolumeSliderThumb, VolumeSliderThumbWrapper, VolumeSliderWrapper, } from "./VolumeController.styles";
const VolumeSlider = ({ value, onChanged, ...props }) => {
    const { muted } = usePlayerState();
    const progressBarRef = useRef(null);
    const onDragChange = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget instanceof Element) {
            event.currentTarget.setPointerCapture(event.pointerId);
        }
        if (!progressBarRef.current)
            return;
        const position = event.clientY;
        const rect = progressBarRef.current.getBoundingClientRect();
        const volume = (rect.bottom - position) / (rect.bottom - rect.top);
        onChanged(clamp(volume));
    }, [onChanged]);
    const { dragging, startDragging } = useDragging({
        onDragChange,
    });
    let volumeSliderProps = props;
    if (dragging) {
        volumeSliderProps = {
            ...volumeSliderProps,
            className: "player-volume-dragging",
        };
    }
    const volumeBarHeight = String(muted ? 0 : 100 * value) + "%";
    return (_jsxs(VolumeSliderWrapper, { ...volumeSliderProps, onPointerDown: startDragging, children: [_jsx(VolumeSliderProgressBar, { children: _jsx(VolumeSliderActualProgress, { style: { height: volumeBarHeight } }) }), _jsx(VolumeSliderThumbWrapper, { ref: progressBarRef, children: _jsx(VolumeSliderThumb, { style: { bottom: volumeBarHeight } }) })] }));
};
export const VolumeController = () => {
    const controls = useControls();
    const { volume, muted } = usePlayerState();
    const { playerMode } = useLayoutSettings();
    const setVolume = useCallback((value) => {
        controls?.setVolume?.(value);
        if (value > 0) {
            controls?.unmute?.();
        }
    }, [controls]);
    const muteHandler = useCallback((_e) => {
        controls?.toggleMute?.();
    }, [controls]);
    const size = useLayoutSize();
    const progressBarSpacing = playerMode !== PlayerMode.PREVIEW;
    return (_jsxs(VolumeControllerWrapper, { "$progressBarSpacing": progressBarSpacing, onPointerUp: (e) => {
            // Stop all pointer up events on the volume slider from propagating
            e.stopPropagation();
            e.preventDefault();
            e.currentTarget.setPointerCapture(e.pointerId);
        }, children: [_jsx(VolumeButton, { className: "player-volume-button", "aria-label": "volume-control", onPointerUp: muteHandler, children: muted ? _jsx(VolumeIconMutedSvg, {}) : _jsx(VolumeIconSvg, {}) }), !isMobile() && size !== LayoutSize.SMALLEST && (_jsx(VolumeSlider, { "$progressBarSpacing": progressBarSpacing, value: volume, onChanged: setVolume }))] }));
};
export default VolumeController;
