import { jsx as _jsx } from "react/jsx-runtime";
import StarsIconSvg from "../icons/stars.svg";
import { PlayerControlButton as Button } from "../styles";
const VotingButton = () => {
    const sendToggleEvent = (e) => {
        e.stopPropagation();
        document.dispatchEvent(new CustomEvent("toggleVote"));
    };
    return (_jsx(Button, { "aria-label": `toggle vote`, onClick: sendToggleEvent, children: _jsx(StarsIconSvg, {}) }));
};
export { VotingButton };
