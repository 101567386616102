import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from "styled-components";
import { DefaultButton, DefaultSvg } from "../styles";
const Button = styled.button `
  ${DefaultButton}
  ${DefaultSvg}

  ${({ $gridArea }) => $gridArea &&
    css `
      grid-area: ${$gridArea};
    `}
  display: flex;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  align-items: flex-start;
  justify-content: center;
`;
const IconButton = ({ onClick, label, hidden, gridArea, children, }) => {
    return (_jsx(Button, { "aria-label": label, "$gridArea": gridArea, onClick: onClick, hidden: hidden, children: children }));
};
export default IconButton;
