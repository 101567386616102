import { jsx as _jsx } from "react/jsx-runtime";
import { isClip } from "@tv4/avod-web-player-common";
import { isChannel, isLiveEventWithoutAds, isMtvNews247, isSports, isStitched, } from "@tv4/avod-web-player-common";
import { createContext, useContext } from "react";
const MetadataContext = createContext({
    asset: undefined,
});
export function MetadataProvider({ asset, poster, children, }) {
    return (_jsx(MetadataContext.Provider, { value: {
            asset,
            poster,
        }, children: children }));
}
export const useMetadata = () => {
    return useContext(MetadataContext);
};
export const useIsChannel = () => {
    const { asset } = useMetadata();
    return isChannel(asset);
};
export const useIsStitched = () => {
    const { asset } = useMetadata();
    return isStitched(asset);
};
export const useIsSports = () => {
    const { asset } = useMetadata();
    return isSports(asset);
};
export const useIsLiveEventWithoutAds = () => {
    const { asset } = useMetadata();
    return isLiveEventWithoutAds(asset);
};
export const useIsMtvNews247 = () => {
    const { asset } = useMetadata();
    return isMtvNews247(asset);
};
export const useIsClip = () => {
    const { asset } = useMetadata();
    return isClip(asset);
};
