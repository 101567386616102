import styled from "styled-components";
import { PlayerControlButton } from "../../styles";
const VOLUME_SLIDER_SPACING = 3;
const PROGRESS_BAR_SPACING = VOLUME_SLIDER_SPACING + 2;
const VOLUME_SLIDER_HEIGHT = 7;
/**
 * @TODO ideally the z-index value should not be needed at all, and instead the dom should be structured correctly so elements are layered as intended
 * PauseAdEngine probably needs refactoring first so it does not manually append the visit-advertiser-link to the existing skin jsx dom, because it
 * will render above the volume slider without manipulating the z-index value for the slider.
 * https://github.com/TV4/avod-web-player/pull/702#issuecomment-2018366988
 */
const SLIDER_LAYER = 3;
const VolumeButton = styled(PlayerControlButton) `
  &:hover {
    z-index: ${SLIDER_LAYER};
  }
`;
const VolumeControllerWrapper = styled.div `
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  &:hover {
  &:hover ${VolumeButton} {
    z-index: ${SLIDER_LAYER};
  }

  // add additional element to increase hover area when hovering so volume slider will not hide when moving pointer from mute button to volume slider
  &:hover:before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    z-index: ${SLIDER_LAYER};
    bottom: 0;
    height: ${({ $progressBarSpacing }) => $progressBarSpacing ? PROGRESS_BAR_SPACING : VOLUME_SLIDER_SPACING}em;
  }
`;
const VolumeSliderWrapper = styled.div `
  width: 2.25em;
  fill: white;
  opacity: 0;
  &:hover,
  ${VolumeControllerWrapper}:hover &,
  &.player-volume-dragging {
    opacity: 1;
    &,
    * {
      pointer-events: auto;
    }
  }
  transition: opacity 0.2s 0.1s;
  box-sizing: border-box;
  cursor: pointer;
  &,
  * {
    pointer-events: none;
  }

  height: ${VOLUME_SLIDER_HEIGHT}em;
  position: absolute;
  bottom: ${({ $progressBarSpacing }) => $progressBarSpacing ? PROGRESS_BAR_SPACING : VOLUME_SLIDER_SPACING}em;
  z-index: ${SLIDER_LAYER};
  margin: 0 auto;
`;
const VolumeSliderThumb = styled.div `
  background-color: #fff;
  box-sizing: content-box;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
`;
const VolumeSliderActualProgress = styled.div `
  background-color: #fff;
  position: absolute;
  width: 0.3em;
  bottom: 0;
  border-radius: 1em;
`;
const VolumeSliderThumbWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
`;
const VolumeSliderProgressBar = styled.div `
  height: 100%;
  background-color: rgba(140, 140, 140, 0.3);
  border-radius: 1em;
  width: 0.3em;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
`;
export { VolumeButton, VolumeControllerWrapper, VolumeSliderActualProgress, VolumeSliderProgressBar, VolumeSliderThumb, VolumeSliderThumbWrapper, VolumeSliderWrapper, };
