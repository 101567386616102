import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState, } from "react";
import styled from "styled-components";
import { useMetadata } from "../providers/MetadataProvider";
import { ageIcons, descriptorIcons, ProductPlacementSvg, } from "./ContentRatingIcons";
const ContentRatingsWrapper = styled.div `
  height: 32px;
  margin-top: 10px;
`;
const ContentRatingItem = styled.span `
  margin-right: 6px;
  height: 32px;
`;
export const ContentRatings = () => {
    const { asset } = useMetadata();
    const [descriptorSVGs, setDescriptorSVGs] = useState([]);
    const contentRating = asset?.contentRating;
    const ageRating = asset?.contentRating?.ageRating;
    const AgeIcon = ageRating
        ? ageIcons.find((icon) => icon.age === asset?.contentRating?.ageRating)
            ?.component
        : null;
    const fetchDescriptorSVGs = useCallback(async () => {
        if (!asset?.contentRating?.descriptors)
            return;
        const icons = asset.contentRating.descriptors.flatMap((descriptor) => descriptorIcons.find((icon) => icon.name === descriptor)?.component ??
            []);
        setDescriptorSVGs(icons);
    }, [asset]);
    useEffect(() => {
        fetchDescriptorSVGs();
    }, [asset, fetchDescriptorSVGs]);
    if (contentRating &&
        (contentRating?.ageRating ||
            contentRating?.productPlacement ||
            (contentRating?.descriptors && contentRating?.descriptors.length > 0))) {
        return (_jsxs(ContentRatingsWrapper, { children: [_jsx(ContentRatingItem, { children: AgeIcon ? _jsx(AgeIcon, {}) : null }), descriptorSVGs.map((DescriptorSVG, index) => (_jsx(ContentRatingItem, { children: _jsx(DescriptorSVG, {}) }, index))), contentRating?.productPlacement && (_jsx(ContentRatingItem, { children: _jsx(ProductPlacementSvg, {}) }))] }));
    }
    return null;
};
export default ContentRatings;
