import { getRandomHexString } from "./random";
import { getData, setData } from "./storage";

const PID_STORAGE_KEY = "persistentAdId";

export function getPersistentID(userId?: string): string {
  if (userId) {
    return userId;
  }
  let pid = getData<string>(PID_STORAGE_KEY);
  if (!pid) {
    pid = getRandomHexString(42);
    setData(PID_STORAGE_KEY, pid);
  }
  return pid;
}
