import { jsx as _jsx } from "react/jsx-runtime";
import { PlaybackMode } from "@tv4/avod-web-player-common";
import styled from "styled-components";
import { usePlayerState, usePlayerStatePolling, } from "../providers/CoreProvider";
import { getZeroBasedTimeString } from "../util/time";
const TimeContainer = styled.div `
  grid-column: span 1 / auto;
  font-variant-numeric: tabular-nums;
  user-select: none;
`;
const TimeLabel = (time) => (_jsx(TimeContainer, { children: _jsx("span", { children: getZeroBasedTimeString(time) }) }));
export const CurrentTime = () => {
    const { playbackMode, preSeeking, currentTime: preSeekingTime, } = usePlayerState();
    const { streamInfo, currentTime: pollingCurrentTime } = usePlayerStatePolling();
    if (preSeeking) {
        return TimeLabel(preSeekingTime);
    }
    if (playbackMode === PlaybackMode.LIVE_DAI && streamInfo.duration) {
        return TimeLabel(streamInfo.currentTime ?? streamInfo.duration);
    }
    return TimeLabel(pollingCurrentTime);
};
export default CurrentTime;
