import { Browser } from "../enums";

export const getBrowserName = (userAgent: string, vendor: string): Browser => {
  const userAgentLower = userAgent.toLowerCase();

  if (/firefox/.test(userAgentLower)) {
    return Browser.FIREFOX;
  }

  if (/chrome/.test(userAgentLower)) {
    if (/edg/.test(userAgentLower)) {
      return Browser.MS_EDGE;
    }
    return Browser.CHROME;
  }

  if (
    /safari/.test(userAgentLower) &&
    /apple computer/.test(vendor.toLowerCase())
  ) {
    return Browser.SAFARI;
  }

  if (/trident.*rv:11\./.test(userAgentLower)) {
    return Browser.MS_IE;
  }

  return Browser.UNKNOWN;
};

export const getBrowserVersion = (
  userAgent: string,
  appVersion: string
): number => {
  const userAgentLower = userAgent.toLowerCase();

  const match: Array<string> =
    userAgentLower.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];

  let tmp: Array<string> | null = null;

  if (/trident/i.test(match[1])) {
    tmp = /\brv[ :]+(\d+)/g.exec(userAgentLower) || [];
    return parseInt(tmp[1], 10) || 0;
  }

  if (match[1] === "chrome") {
    tmp = userAgentLower.match(/\b(opr|edge)\/(\d+)/);
    if (tmp != null) {
      return parseInt(tmp.slice(1)[1], 10);
    }
  } else if (match[1] === "safari") {
    tmp = userAgentLower.match(/version\/(\d+)/i);
  }

  let result: string = match[2] ? match[2] : appVersion;

  if (tmp != null) {
    result = tmp[1];
  }

  return parseInt(result, 10);
};
