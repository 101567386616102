import {
  AWPTextTrack,
  AWPTextTrackKind,
  HTMLTextTrackSize,
} from "../models/track-models";
import * as storage from "./storage";
import { DISABLED_TEXT } from "./videoLanguage";

// TODO rewrite local/session storage with type safety

export function get(key: string): any {
  /**
   * original implementation of some preferences
   * were using localStorage directly instead of
   * storage module.
   * storage module is using a key prefix so it
   * will not find values stored directly.
   * move value from original implementation to
   * use storage module.
   * @TODO this is a temporary workaround and
   * should be fine to remove after this has
   * been in production for a month or so.
   */
  {
    const value = window.localStorage.getItem(key);
    if (value) {
      window.localStorage.removeItem(key);
      storage.setData(key, value);
    }
  }

  return storage.getData(key);
}

export function set(key: string, value: any): void {
  /**
   * original implementation of some preferences
   * were using localStorage directly instead of
   * storage module.
   * @TODO this is a temporary workaround and
   * should be fine to remove after this has
   * been in production for a month or so.
   */
  window.localStorage.removeItem(key);

  return storage.setData(key, value);
}

const preferredAudioKey: string = "selectedAudioTrack";

export function getPreferredAudio(): string {
  return get(preferredAudioKey) || "";
}

export function setPreferredAudio(value: string): void {
  return set(preferredAudioKey, value);
}

const preferredLiveTextKey: string = "selectedTextTrackLive";
const preferredTextKey: string = "selectedTextTrack";

export function getPreferredText(isLive: boolean): string | null {
  return get(isLive ? preferredLiveTextKey : preferredTextKey) || null;
}

export function setPreferredText(
  textTrack?: AWPTextTrack,
  isLive?: boolean
): void {
  const value: string | undefined =
    textTrack?.kind === AWPTextTrackKind.NOTHING
      ? DISABLED_TEXT
      : textTrack?.language || undefined;

  return set(isLive ? preferredLiveTextKey : preferredTextKey, value);
}

const preferredTextSizeKey = "textTrackSize";

export function getPreferredTextSize(): string | undefined {
  let value: string = String(get(preferredTextSizeKey));
  const size: number = Number(value) || 0;

  /**
   * value was originally stored as pixel value, and later as a relative value.
   * map numerical value against constant reference.
   * This is a temporary check until it can be assumed that all users are using the new text track sizes
   * @TODO remove this mapping a month or so after this has been in production
   */
  switch (size) {
    case 40:
    case 5.5:
      value = HTMLTextTrackSize.XLARGE;
      break;
    case 32:
    case 4.5:
      value = HTMLTextTrackSize.LARGE;
      break;
    case 24:
    case 3.5:
      value = HTMLTextTrackSize.MEDIUM;
      break;
    case 18:
    case 2.25:
      value = HTMLTextTrackSize.SMALL;
      break;
  }

  // return only valid value
  return Object.keys(HTMLTextTrackSize).includes(value) ? value : undefined;
}

export function setPreferredTextSize(value?: string): void {
  return set(preferredTextSizeKey, value || undefined);
}
