export const getZeroBasedTimeString = (totalSeconds) => {
    // Returns a string with an amount of hours, minutes and seconds
    // Based on http://jsfiddle.net/StevenIseki/apg8yx1s/
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
    const timeArray = [minutes, seconds];
    if (hours > 0) {
        timeArray.unshift(hours);
    }
    return timeArray
        .map((num) => {
        if (isNaN(num)) {
            return "00";
        }
        if (num < 10) {
            return "0" + num;
        }
        return num;
    })
        .join(":");
};
