export enum ChromeCastConnectionState {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}

export interface ChromeCastState {
  connectionState?: ChromeCastConnectionState;
}

export interface ReceiverError {
  code: string;
  message: string;
}
