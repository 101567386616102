import { jsx as _jsx } from "react/jsx-runtime";
import { PlaybackState } from "@tv4/avod-web-player-common";
import { useCallback } from "react";
import PauseSvg from "../icons/pause.svg";
import PlaySvg from "../icons/play.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";
export const PlayPauseButton = () => {
    const { playbackState } = usePlayerState();
    const controls = useControls();
    const togglePlayPause = useCallback(() => {
        if ([PlaybackState.PAUSED, PlaybackState.ENDED].includes(playbackState)) {
            controls?.play?.();
        }
        else {
            controls?.pause?.();
        }
    }, [playbackState, controls]);
    return (_jsx(Button, { "aria-label": "start playback", onClick: togglePlayPause, children: playbackState === PlaybackState.PAUSED ? _jsx(PlaySvg, {}) : _jsx(PauseSvg, {}) }));
};
export default PlayPauseButton;
