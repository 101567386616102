import { Asset, AssetType, PlaybackApiAssetType } from "../models/data-models";

export function isChannel(asset?: Asset): boolean {
  return (
    asset?.playbackApiAssetType === PlaybackApiAssetType.CHANNEL &&
    !isMtvNews247(asset)
  );
}

export function isStitched(asset?: Asset): boolean {
  return asset?.isStitched ?? false;
}

export function isSports(asset?: Asset): boolean {
  return asset?.playbackApiAssetType === PlaybackApiAssetType.SPORTVIDEO;
}

export function isMtvNews247(asset?: Asset): boolean {
  return isMtvNews247Id(asset?.assetId);
}

export function isMtvNews247Id(id: string | undefined): boolean {
  return id === "20025962" || id === "9e6393959b5c6ccb71ea";
}

export function isClip(asset?: Asset): boolean {
  return asset?.playbackApiAssetType === PlaybackApiAssetType.CLIP;
}

export function isLiveEventWithoutAds(asset?: Asset): boolean {
  return (
    asset?.type === AssetType.LIVE &&
    !isStitched(asset) &&
    asset.playbackApiAssetType !== PlaybackApiAssetType.CHANNEL
  );
}
