import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaybackMode } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import styled from "styled-components";
import GoToStartOverArrow from "../icons/goToStartOverArrow.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { StartOverButtons } from "../styles";
import { LayoutSize } from "../util/device";
const Button = styled.button `
  ${StartOverButtons}
  opacity: ${({ isStartOver }) => (isStartOver ? 0.7 : 1)};
  svg {
    margin-right: 5px;
  }
`;
const GoToStartOverButton = () => {
    const translations = useTranslations();
    const controls = useControls();
    const { playbackMode } = usePlayerState();
    const layoutSize = useLayoutSize();
    const useShortText = !isMobile() && [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize);
    const handleGoToStartOverClick = () => {
        controls?.seekTo?.(0);
    };
    const buttonText = translations(useShortText ? "start_over__start_over_shortened" : "start_over__start_over");
    return (_jsxs(Button, { "aria-label": "navigate back", onClick: handleGoToStartOverClick, isStartOver: playbackMode === PlaybackMode.START_OVER, children: [_jsx(GoToStartOverArrow, {}), _jsx("span", { children: buttonText })] }));
};
export default GoToStartOverButton;
