import styled from "styled-components";
import { theme } from "../theme";
import { fontSizes } from "../theme/font-sizes";
import { withBreakpoints } from "../utils/responsive-styles";
const body1 = {
    fontSize: [3, 4, 5],
    lineHeight: theme.lineHeights.body,
};
const body2 = {
    fontSize: [2, 3, 4],
    lineHeight: theme.lineHeights.body,
};
const body3 = {
    fontSize: [1, 2, 3],
    lineHeight: theme.lineHeights.body,
};
const variants = {
    title1: {
        fontSize: [7, 8, 9],
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.heading,
    },
    title2: {
        fontSize: [6, 7, 8],
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.heading,
    },
    title3: {
        ...body1,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.heading,
    },
    body1,
    body1Strong: {
        ...body1,
        fontWeight: theme.fontWeights.medium,
    },
    body2,
    body2Strong: {
        ...body2,
        fontWeight: theme.fontWeights.medium,
    },
    body3,
    body3Strong: {
        ...body3,
        fontWeight: theme.fontWeights.medium,
    },
    body4: {
        fontSize: [0, 1, 2],
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.body,
    },
    CTA: {
        fontSize: 2,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.medium,
    },
    label: {
        fontSize: 0,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.small,
    },
    meta: {
        fontSize: [1, 2, 3],
        lineHeight: theme.lineHeights.body,
    },
    cardDetail: {
        fontSize: [1, 1, 2],
        lineHeight: theme.lineHeights.medium,
    },
    category: {
        fontSize: [2, 3, 7],
        fontWeight: theme.fontWeights.medium,
    },
    tab: {
        fontSize: [2, 3],
        fontWeight: theme.fontWeights.regular,
    },
};
export const TextComponent = styled.div(({ variant, color, fontSize }) => {
    const { fontSize: variantFontSize, ...otherVariantProps } = variants[variant];
    const actualFontSize = fontSize ?? variantFontSize;
    return {
        ...otherVariantProps,
        ...withBreakpoints((value) => ({
            fontSize: fontSizes[value],
        }), actualFontSize),
        ...(color ? { color: theme.colors[color] } : {}),
    };
});
