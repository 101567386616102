const loaded: Record<string, Promise<void>> = {};

export default function loadScript(src: string): Promise<void> {
  loaded[src] =
    loaded[src] ||
    new Promise((resolve, reject) => {
      const fail = (e: unknown): void => {
        delete loaded[src];
        reject(e);
      };
      try {
        const script = window.document.createElement("script");
        script.addEventListener("error", fail);
        script.addEventListener("load", () => resolve());
        script.setAttribute("async", "");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", src);
        window.document.getElementsByTagName("head")[0].appendChild(script);
      } catch (e: unknown) {
        fail(e);
      }
    });
  return loaded[src];
}
