export var LayoutSize;
(function (LayoutSize) {
    LayoutSize["SMALLEST"] = "SMALLEST";
    LayoutSize["SMALL"] = "SMALL";
    LayoutSize["MEDIUM"] = "MEDIUM";
    LayoutSize["LARGE"] = "LARGE";
})(LayoutSize || (LayoutSize = {}));
export var LayoutOrientation;
(function (LayoutOrientation) {
    LayoutOrientation["PORTRAIT"] = "PORTRAIT";
    LayoutOrientation["LANDSCAPE"] = "LANDSCAPE";
})(LayoutOrientation || (LayoutOrientation = {}));
export const LayoutBreakpoints = {
    [LayoutSize.SMALL]: 512,
    [LayoutSize.MEDIUM]: 768,
    [LayoutSize.LARGE]: 1024,
};
export function getWidthLayoutSize(width) {
    if (width >= LayoutBreakpoints[LayoutSize.LARGE]) {
        return LayoutSize.LARGE;
    }
    else if (width >= LayoutBreakpoints[LayoutSize.MEDIUM]) {
        return LayoutSize.MEDIUM;
    }
    else if (width >= LayoutBreakpoints[LayoutSize.SMALL]) {
        return LayoutSize.SMALL;
    }
    return LayoutSize.SMALLEST;
}
export function getContainerLayoutSize(container) {
    const clientWidth = container.clientWidth;
    return getWidthLayoutSize(clientWidth);
}
export function getLayoutOrientation(width, height) {
    return width > height
        ? LayoutOrientation.LANDSCAPE
        : LayoutOrientation.PORTRAIT;
}
