import { jsx as _jsx } from "react/jsx-runtime";
import EnterFullscreenSvg from "../icons/enterFullscreen.svg";
import ExitFullscreenSvg from "../icons/exitFullscreen.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";
export const FullscreenButton = () => {
    const controls = useControls();
    const { isFullscreen } = usePlayerState();
    const toggleFullscreen = (e) => {
        e.stopPropagation();
        controls?.toggleFullscreen?.();
    };
    return (_jsx(Button, { "aria-label": "Enter fullscreen", onClick: toggleFullscreen, children: isFullscreen ? _jsx(ExitFullscreenSvg, {}) : _jsx(EnterFullscreenSvg, {}) }));
};
export default FullscreenButton;
