{
  "ad_break_overlay__ad_counter": "Annons {adSequenceIndex} av {adBreakTotalAds}",
  "ad_break_overlay__ad_starts_in": {
    "one": "Annons visas om {number} sekund",
    "other": "Annons visas om {number} sekunder"
  },
  "ad_break_overlay__video_starts_in": {
    "one": "Annons. Videon börjar om {number} sekund.",
    "other": "Annons. Videon börjar om {number} sekunder."
  },
  "ad_break_overlay__visit_advertiser": "Besök annonsör",
  "ad_indicator__ad": "Annons",
  "channels": "Kanaler",
  "chromecast__commercial": "Spelar reklam på Chromecast",
  "chromecast__error": "Chromecast-fel: {error}",
  "chromecast__loading": "Laddar",
  "chromecast__playing": "Spelar på Chromecast",
  "controlls__jump_to_live_title": "Titta live",
  "controlls__pause_title": "Paus",
  "controlls__start_over_title": "Starta om",
  "controlls__voting_title": "Röstningsläge",
  "cta__cast__close": "Close",
  "cta__push_next__see_credits": "See credits",
  "end_poster__play_from_beginning": "Spela upp från början",
  "error__expired_vod": "%1$s is no longer available.\\nIt was removed %2$s",
  "error__playback_drm": "An error occurred (DRM)",
  "error__playback_hdmi": "An error occurred (HDMI)",
  "error__subscription": "Change your subscription to get access to this content.",
  "error__subscription_title": "Your subscription does not give you access to this video",
  "error__user_denied": "You do not have the right to watch this video",
  "error_message__ad_blocker__header": "Har du en annonsblockerare igång?",
  "error_message__ad_blocker__message": "För att ta del av vårt innehåll behöver du avaktivera din annonsblockerare och ladda om sidan.",
  "error_message__problem_general": "Något gick fel",
  "error_message__stream_limit__button_back": "Tillbaka",
  "error_message__stream_limit__button_close": "Avsluta",
  "error_message__stream_limit__button_details_about_streaming": "Detaljer om din uppspelning",
  "error_message__stream_limit__button_details_log_out_instructions": "Behöver du logga ut dig från andra enheter än denna kan du gå in på tv4.se/inställningar",
  "error_message__stream_limit__button_details_log_out_instructions_short": "Du kan logga ut ifrån andra enheter på tv4.se/inställningar",
  "error_message__stream_limit_details__header_playing_now": "Just nu spelas: ",
  "error_message__stream_limit_single_stream__header": "{eventTitle} spelas redan på en annan enhet",
  "error_message__stream_limit_single_stream__header_this_stream": "Den här strömmen",
  "error_message__stream_limit_single_stream__message": "Det här evenemanget går endast att se på en enhet i taget. Vill du se på den här enheten måste strömmen på din andra enhet upphöra.",
  "error_message__stream_limit_single_stream__message_shortened": "Det här evenemanget går endast att se på en enhet i taget.",
  "error_message__stream_limit_stream_quota__header": "Ditt konto används på för många enheter just nu",
  "error_message__stream_limit_stream_quota__message": "Det går endast att spela upp innehåll på maximalt två enheter samtidigt per konto. Vill du spela upp något på den här enheten måste en av strömmarna på din andra enhet upphöra först.",
  "error_message__stream_limit_stream_quota__message_shortened": "Maximalt antal strömmar används",
  "inactive__continue_watching_no": "Nej, avbryt",
  "inactive__continue_watching_question": "Vill du fortsätta titta?",
  "inactive__continue_watching_yes": "Ja fortsätt",
  "language__audio": "Ljud",
  "language__da": "Danska",
  "language__de": "Tyska",
  "language__default": "Originalversion",
  "language__dub": "Dubbat",
  "language__dubbing": "Dubbat",
  "language__el": "Grekiska",
  "language__en": "Engelska",
  "language__es": "Spanska",
  "language__fi": "Finska",
  "language__fr": "Franska",
  "language__it": "Italienska",
  "language__live": "Live captioning",
  "language__nb": "Norskt bokmål",
  "language__nl": "Nederländska",
  "language__no": "Norska",
  "language__no_subtitles": "Inga undertexter",
  "language__off": "Av",
  "language__pt": "Portugisiska",
  "language__qaa": "Utan kommentator",
  "language__qaf": "Uppläst undertext",
  "language__qag": "Direkttextning",
  "language__qai": "Svenska (AI-genererad)",
  "language__qol": "Original",
  "language__subtitle": "Undertext",
  "language__subtitles": "Undertexter",
  "language__subtitles_and_audio": "Undertexter och Ljud",
  "language__subtitles_off": "Av",
  "language__subtitles_size": "Storlek på text",
  "language__sv": "Svenska",
  "language__zh": "Kinesiska",
  "language_ar": "Arabiska",
  "language_bg": "Bulgariska",
  "language_cs": "Tjeckiska",
  "language_dk": "Danska",
  "language_el": "Grekiska",
  "language_et": "Estniska",
  "language_fa": "Persiska",
  "language_he": "Hebreiska",
  "language_hi": "Hindi",
  "language_hr": "Kroatiska",
  "language_hu": "Ungerska",
  "language_is": "Isländska",
  "language_it": "Italienska",
  "language_ja": "Japanska",
  "language_lt": "Litauiska",
  "language_lv": "Lettiska",
  "language_mk": "Makedonska",
  "language_no": "Norska",
  "language_off": "Av",
  "language_pl": "Polska",
  "language_pt": "Portugisiska",
  "language_qaa": "Utan kommentering",
  "language_qad": "Arenaljud",
  "language_qd": "Arenaljud",
  "language_qhi": "Nedsatt hörsel",
  "language_qvi": "Nedsatt syn",
  "language_ro": "Rumänska",
  "language_role_direct": "Direkttextning",
  "language_ru": "Ryska",
  "language_sk": "Slovakiska",
  "language_sl": "Slovenska",
  "language_sq": "Albanska",
  "language_sr": "Serbiska",
  "language_title": "Språk",
  "language_tr": "Turkiska",
  "language_uk": "Ukrainska",
  "language_zh": "Kinesiska",
  "new__channels_open_table": "Öppna tablån",
  "new__channels_overlay_title": "Kanaler",
  "player__controls__play": "Play",
  "player__generic_action_disabled__message": "Denna funktion är tyvärr inte tillgänglig för detta program.",
  "player__pausead_information": "Ad. Press play to continue",
  "player__text_size__large": "Stor",
  "player__text_size__medium": "Mellan",
  "player__text_size__small": "Liten",
  "player__text_size__x_large": "Extra stor",
  "program_metadata__episode": "Avsnitt",
  "program_metadata__season": "Säsong",
  "quality__high": "Hög",
  "quality__low": "Låg",
  "quality__medium": "Medium",
  "quality__title": "Kvalité",
  "quality__unlimited": "Obegränsat",
  "refresh_page": "Ladda om sidan",
  "skip_intro": "Hoppa över intro",
  "skip_recap": "Hoppa över sammanfattning",
  "start_over__start_over": "Börja om",
  "start_over__start_over_shortened": "Början",
  "start_over__watch_live": "Titta live",
  "start_over__watch_live_shortened": "Live",
  "start_over_choice__continue_playing_live_counter": "Fortsätt spela live om {secondsLeft} sekunder",
  "start_over_choice__watch_from_beginning": "Se från början",
  "start_over_choice__watch_live": "Se live",
  "streaming__device_other": "Annan",
  "streaming__locations_title": "Streamar nu",
  "streaming__open_locations": "Detaljer om din uppspelning"
}
