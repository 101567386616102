export const text = {
    body: {
        color: "text",
        fontFamily: "body",
        fontWeight: "body",
        lineHeight: "body",
        fontSize: 3,
    },
    heading: {
        fontFamily: "heading",
        fontWeight: "heading",
        lineHeight: "heading",
    },
    h1: {
        fontSize: 9,
    },
    h2: {
        fontSize: 8,
    },
    h3: {
        fontSize: 7,
    },
    h4: {
        fontSize: 6,
    },
    h5: {
        fontSize: 5,
    },
    largeText: {
        fontSize: [2, 3, 4, 4, 4, 5],
    },
    largeHeading: {
        fontSize: [7, 8, 8, 9, 9, 10],
    },
    p: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        fontSize: 3,
        mb: 1,
    },
    small: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        fontSize: 2,
    },
    smaller: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        fontSize: 1,
    },
    smallest: {
        color: "text",
        fontFamily: "body",
        lineHeight: "body",
        fontSize: 0,
    },
};
