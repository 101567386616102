export enum Browser {
  CHROME = "GoogleChrome",
  SAFARI = "AppleSafari",
  MS_IE = "MicrosoftInternetExplorer",
  MS_EDGE = "MicrosoftEdge",
  FIREFOX = "MozillaFirefox",
  UNKNOWN = "Unknown",
}

export enum OperatingSystem {
  WINDOWS = "windows",
  MAC_OS = "macos",
  IOS = "iOS",
  IPAD_OS = "iPadOS",
  ANDROID = "android",
  LINUX = "linux",
  CHROME_OS = "chromeos",
  CHROMECAST = "chromecast",
  BLACKBERRY = "blackberry",
  WINDOWS_PHONE = "windowsphone",
  UNKNOWN = "",
}

export enum SupportedProtocol {
  HLS = "hls",
  HLS_DASH = "hls,dash",
}
