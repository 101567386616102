import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import { theme } from "@tv4/avod-web-player-styling";
import styled from "styled-components";
const { colors } = theme;
import { LayoutSize } from "../../util/device";
export const VisitAdvertiserButton = styled.button `
  position: absolute;
  right: ${isDesktopOS() ? 2 : 1}em;
  z-index: 2;
  color: ${colors.white};
  display: flex;
  align-items: center;
  padding: 0.3em 0.6em;
  background: rgba(0, 0, 0, 0.32);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  bottom: ${({ size }) => size === LayoutSize.SMALLEST ? "35%" : isDesktopOS() ? "6em" : "2.5em"};
`;
export const AdBreakOverlayContainer = styled.div `
  font-size: ${({ size }) => isDesktopOS() && size === LayoutSize.SMALL
    ? 0.75
    : size === LayoutSize.SMALLEST
        ? 0.5
        : 1}em;

  button {
    font-size: ${({ size }) => size === LayoutSize.SMALLEST ? "8px" : "inherit"};
  }
`;
export const AdCount = styled.div `
  color: ${colors.white};
  position: absolute;
  left: ${({ makeSpaceForBackButton }) => makeSpaceForBackButton ? (isDesktopOS() ? 4 : 3) : 1}em;
  top: 1.1em;
  background: rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
