// bundled version is used as fallback if the remote version cannot be loaded
import { isbot as isbotFallback } from "isbot";

import loadScript from "./loadScript";

declare global {
  interface Window {
    // remote script will declare isbot as global variable
    isbot: (ua: string) => boolean;
  }
}

export async function isbot(): Promise<boolean> {
  let result: boolean | undefined;

  try {
    /**
     * latest defined version of isbot is loaded from external url to
     * ensure it is using up to date definitions.
     * major version is defined in url so a newer minor/patch version
     * should not include any breaking changes, but the latest revision
     * of the defined major version will be used.
     */
    /**
     * ⚠️ different versions may define the global variable different,
     *    so take care to test that the function is still accessed
     *    correctly when updating the version here.
     */
    await loadScript("https://cdn.jsdelivr.net/npm/isbot@5");
    // result from remote script version
    result = window.isbot(window.navigator.userAgent);
  } catch (e: unknown) {
    console.warn(e);
  }

  try {
    // if there is no result then the remote script failed for some reason - use the fallback
    result = result ?? isbotFallback(window.navigator.userAgent);
  } catch (e: unknown) {
    console.warn(e);
  }

  // if there is no value then the default is false to treat as not bot
  return result || false;
}
