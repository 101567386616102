export enum VisualElement {
  VOTING_BUTTON = "VOTING_BUTTON",
  CHANNELS_BUTTON = "CHANNELS_BUTTON",
}

export enum PlayerMode {
  DEFAULT = "default",
  MINI = "mini",
  PREVIEW = "preview",
}
