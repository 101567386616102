import { Browser, OperatingSystem, SupportedProtocol } from "./enums";
import { getBrowserName, getBrowserVersion } from "./util/browser";
import {
  canDevicePlayNativeHls,
  canDevicePlayVideo,
} from "./util/can-play-video";
import { getOS, isChromecastUltra, isMobile } from "./util/os";
import {
  getAppVersion,
  getPlatform,
  getUserAgent,
  getVendor,
} from "./util/window";

const USER_AGENT = getUserAgent();
const VENDOR = getVendor();
const PLATFORM = getPlatform();
const APP_VERSION = getAppVersion();

export const OS_NAME = getOS(USER_AGENT, PLATFORM);
export const BROWSER_NAME = getBrowserName(USER_AGENT, VENDOR);
export const BROWSER_VERSION = getBrowserVersion(USER_AGENT, APP_VERSION);

export const IS_WINDOWS = OS_NAME === OperatingSystem.WINDOWS;
export const IS_MAC_OS = OS_NAME === OperatingSystem.MAC_OS;
export const IS_IOS = OS_NAME === OperatingSystem.IOS;
export const IS_IPAD_OS = OS_NAME === OperatingSystem.IPAD_OS;
export const IS_ANDROID = OS_NAME === OperatingSystem.ANDROID;
export const IS_CHROME_OS = OS_NAME === OperatingSystem.CHROME_OS;
export const IS_LINUX = OS_NAME === OperatingSystem.LINUX;

export const IS_SUPPORTED_OS =
  IS_WINDOWS || IS_MAC_OS || IS_LINUX || IS_CHROME_OS;

export const IS_CHROMECAST = OS_NAME === OperatingSystem.CHROMECAST;
export const IS_CHROMECAST_ULTRA = isChromecastUltra(OS_NAME, USER_AGENT);
export const IS_MOBILE = isMobile(OS_NAME);

export const IS_CHROME = BROWSER_NAME === Browser.CHROME;
export const IS_FIREFOX = BROWSER_NAME === Browser.FIREFOX;
export const IS_MS_EDGE = BROWSER_NAME === Browser.MS_EDGE;
export const IS_MS_IE = BROWSER_NAME === Browser.MS_IE;
export const IS_SAFARI = BROWSER_NAME === Browser.SAFARI;

export const CAN_CAST = IS_CHROME && !IS_MOBILE;

export const SUPPORTED_PROTOCOLS =
  IS_IOS || IS_SAFARI ? SupportedProtocol.HLS : SupportedProtocol.HLS_DASH;

export const NATIVE_HLS_SUPPORTED = canDevicePlayNativeHls({
  isLive: false,
  isAndroid: IS_ANDROID,
  isChromecast: IS_CHROMECAST,
  isMsEdge: IS_MS_EDGE,
});
export const NATIVE_LIVE_HLS_SUPPORTED = canDevicePlayNativeHls({
  isLive: true,
  isAndroid: IS_ANDROID,
  isChromecast: IS_CHROMECAST,
  isMsEdge: IS_MS_EDGE,
});
export const CAN_PLAY_VIDEO = canDevicePlayVideo(NATIVE_HLS_SUPPORTED);

export const ENVIRONMENT_SUPPORTS_DOWNLINK =
  typeof window !== "undefined" &&
  "navigator" in window &&
  "connection" in navigator &&
  typeof navigator.connection === "object" &&
  navigator.connection !== null &&
  "downlink" in navigator.connection &&
  typeof navigator.connection.downlink === "number";
