import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePlayerState } from "../../providers/CoreProvider";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { LayoutSize } from "../../util/device";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import { Body, Bottom, ButtonGroup, ButtonsContainer, ControlsContainer, Middle, Top, } from "../DefaultControls/DefaultControls.styles";
import VolumeController from "../VolumeController/VolumeController";
export function SkinPreviewControls() {
    const { isFullscreen } = usePlayerState();
    const size = useLayoutSize();
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    const showMetaData = isFullscreen && [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
    return (_jsxs(ControlsContainer, { "$compact": isSmallestLayout, children: [_jsx(Body, { "$isFullscreen": isFullscreen, children: showMetaData && (_jsxs(_Fragment, { children: [_jsx(Top, { children: _jsx(CurrentProgramMetaData, { hidden: false }) }), _jsx(Middle, {}), _jsx(Bottom, {})] })) }), _jsx("div", {}), _jsxs(ButtonsContainer, { children: [_jsx(ButtonGroup, { "$position": "left" }), _jsx(ButtonGroup, { "$position": "center" }), _jsx(ButtonGroup, { "$position": "right", children: _jsx(VolumeController, {}) })] })] }));
}
