import { IntlMessageFormat } from "intl-messageformat";

import Finnish from "./localization/fi.json";
import Swedish from "./localization/sv.json";
import { Service } from "./models/data-models";
import { isMtvOrUutisetService } from "./utils/service-type";
export type Locale = "sv" | "fi";

export const languages = {
  sv: Swedish,
  fi: Finnish,
};

export function localeFromService(service: Service): Locale {
  return isMtvOrUutisetService(service) ? "fi" : "sv";
}

const translations: Record<
  string,
  (
    key: keyof (typeof languages)[Locale],
    values?: Record<string, any>
  ) => string
> = {};

export function getTranslationsForLocale<L extends Locale>(locale: L) {
  /**
   * getTranslationsForLocale is used by useTranslations hook,
   * so the return values here are cached to avoid additional
   * rendering if the hook return value changes on each render.
   */
  translations[locale] =
    translations[locale] ||
    function (
      key: keyof (typeof languages)[L],
      values?: Record<string, any>
    ): string {
      try {
        const translation = languages[locale][key];
        return new IntlMessageFormat(translation as string).format(
          values
        ) as string;
      } catch (error) {
        console.warn("Received key: ", key, "Which resulted in error: ", error);
        return key as string;
      }
    };
  return translations[locale];
}
