import { OperatingSystem } from "../enums";

export const getOS = (userAgent: string, platform: string): OperatingSystem => {
  const userAgentLowerCase = userAgent.toLowerCase();

  if (/windows nt/.test(userAgentLowerCase)) {
    return OperatingSystem.WINDOWS;
  }

  if (/android/.test(userAgentLowerCase) && !/crkey/.test(userAgentLowerCase)) {
    return OperatingSystem.ANDROID;
  }

  if (
    platform.toLowerCase().indexOf("mac") >= 0 ||
    /macintosh/.test(userAgentLowerCase)
  ) {
    return document && "ontouchstart" in document.documentElement
      ? OperatingSystem.IPAD_OS
      : OperatingSystem.MAC_OS;
  }

  if (/iphone|ipod/.test(userAgentLowerCase)) {
    return OperatingSystem.IOS;
  }

  if (/ipad/.test(userAgentLowerCase)) {
    return OperatingSystem.IPAD_OS;
  }

  if (/crkey/.test(userAgentLowerCase)) {
    return OperatingSystem.CHROMECAST;
  }

  if (
    (platform.toLowerCase().indexOf("linux") >= 0 ||
      /linux/.test(userAgentLowerCase)) &&
    !/crkey/.test(userAgentLowerCase)
  ) {
    return OperatingSystem.LINUX;
  }

  if (/cros/.test(userAgentLowerCase)) {
    return OperatingSystem.CHROME_OS;
  }

  if (/blackberry/.test(userAgentLowerCase)) {
    return OperatingSystem.BLACKBERRY;
  }

  if (/windows phone|iemobile|wpdesktop/.test(userAgentLowerCase)) {
    return OperatingSystem.WINDOWS_PHONE;
  }

  return OperatingSystem.UNKNOWN;
};

export const isChromecastUltra = (
  operatingSystem: OperatingSystem,
  userAgent: string
): boolean =>
  operatingSystem === OperatingSystem.CHROMECAST &&
  /aarch64/.test(userAgent.toLowerCase());

export const isMobile = (operatingSystem: OperatingSystem): boolean => {
  switch (operatingSystem) {
    case OperatingSystem.ANDROID:
    case OperatingSystem.IOS:
    case OperatingSystem.IPAD_OS:
    case OperatingSystem.BLACKBERRY:
    case OperatingSystem.WINDOWS_PHONE:
      return true;
    case OperatingSystem.MAC_OS:
      return document && "ontouchstart" in document.documentElement;
    default:
      return false;
  }
};
