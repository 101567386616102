import { colors } from "./colors";
export const link = {
    position: "relative",
    fontWeight: 500,
    color: colors.mediumred,
    textDecoration: "none",
    "&:hover": {
        textDecoration: "underline",
    },
    ".touch &:hover": {
        textDecoration: "none",
    },
    "&:focus, &:focus-visible, &focus-within": {
        outline: "none",
    },
    ".no-touch &:focus-visible": {
        textDecoration: "underline",
        "::after": {
            content: '""',
            position: "absolute",
            display: "block",
            top: "-4px",
            left: "-8px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: colors.mediumred,
            borderRadius: "8px",
            width: "calc(100% + 16px)",
            height: "calc(100% + 8px)",
            zIndex: 1,
        },
    },
};
