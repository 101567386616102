import { uuid } from "./random";

type JWTTokenPayload = {
  aud?: string;
  entitlement_source?: string;
  entitlements?: string[];
  exp?: number;
  iat?: number;
  iss?: string;
  jti?: string;
  nbf?: number;
  profile_id?: string;
  session_id?: string;
  type?: string;
  uid?: string;
  [key: string]: any;
};

export function parseJwtToken(token: string): JWTTokenPayload | undefined {
  const parts = token?.replace("Bearer ", "")?.split(".");
  if (!parts?.length) {
    return;
  }
  try {
    const header = JSON.parse(atob(parts[0]));
    if (header?.typ === "JWT") {
      return JSON.parse(atob(parts[1]));
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- no-op
  } catch (e) {
    // no-op
  }
  return;
}

export function getUserId(accessToken: string | undefined): string | undefined {
  return accessToken ? parseJwtToken(accessToken)?.uid : undefined;
}

export function getProfileId(accessToken: string): string | undefined {
  return parseJwtToken(accessToken)?.profile_id;
}

export function loggedIn(accessToken: string): boolean {
  return (getUserId(accessToken) || "") !== "";
}

export function generateSessionId(): string {
  return uuid();
}

let trackingSessionId: string;

/**
 * getTrackingSessionId is deprecated. Please use core.sessionId instead
 */
export function getTrackingSessionId(resetValue?: boolean): string {
  if (!trackingSessionId || resetValue) {
    trackingSessionId = generateSessionId();
  }
  return trackingSessionId;
}
