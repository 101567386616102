import { MimeTypesArray } from "../constants";

let canPlayVideo: boolean;

type TCanPlayNativeHlsOptions = {
  isAndroid: boolean;
  isChromecast: boolean;
  isMsEdge: boolean;
  isLive: boolean;
};

let testNativeHlsResult: boolean | undefined;

const testNativeHls = (): boolean => {
  if (testNativeHlsResult !== undefined) return testNativeHlsResult;

  try {
    testNativeHlsResult = !!(
      typeof window !== "undefined" &&
      window.document
        .createElement("video")
        .canPlayType("application/vnd.apple.mpegurl")
    );
  } catch (_) {
    testNativeHlsResult = false;
  }

  return testNativeHlsResult;
};

export const canDevicePlayNativeHls = ({
  isAndroid,
  isChromecast,
  isMsEdge,
  isLive,
}: TCanPlayNativeHlsOptions): boolean => {
  if (isChromecast || isAndroid || (isMsEdge && isLive)) {
    return false;
  }

  return testNativeHls();
};

const isWindowMediaSourceSupported = (): boolean => {
  if (typeof window === "undefined" || !window.MediaSource) return false;

  for (const type of MimeTypesArray) {
    if (window.MediaSource.isTypeSupported(type)) return true;
  }

  return false;
};

export const canDevicePlayVideo = (canPlayNativeHls: boolean): boolean => {
  if (canPlayNativeHls) return true;
  if (canPlayVideo !== undefined) return canPlayVideo;

  if (isWindowMediaSourceSupported()) {
    canPlayVideo = true;
    return canPlayVideo;
  }

  canPlayVideo = canPlayNativeHls;

  return canPlayVideo;
};
