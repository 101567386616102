import styled, { css } from "styled-components";
import { PlayerControlButton } from "../../styles";
import { LayoutBreakpoints, LayoutOrientation, LayoutSize, } from "../../util/device";
export const ControlsContainer = styled.div `
  display: grid;
  grid-template-rows: 1fr minmax(20px, auto) minmax(30px, auto);
`;
export const ProgressContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ButtonsContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
`;
export const ButtonGroup = styled.div `
  justify-self: ${({ $position }) => $position};
  display: grid;
  grid: repeat(1, 1fr) / auto-flow;
  gap: 3.75em;
`;
export const LargeButtonsContainer = styled(ButtonsContainer) `
  position: relative;
  width: 100%;
  ${PlayerControlButton} {
    height: 100%;
    max-height: 5em;
    max-width: 5em;

    svg {
      max-width: 3em;
      max-height: 3em;
    }

    &:nth-child(2) {
      svg {
        max-width: 5em;
        max-height: 5em;
      }
    }
  }
`;
export const Body = styled.div `
  display: grid;
  grid-template-rows: ${({ isFullscreen }) => isFullscreen ? "1.1fr auto 1fr" : "auto 1fr auto"};
  grid-template-columns: auto;
  gap: ${({ isFullscreen }) => (isFullscreen ? "1em" : "0")};

  ${({ isFullscreen, orientation }) => isFullscreen &&
    css `
      @media screen and (max-width: ${LayoutBreakpoints[LayoutSize.MEDIUM]}px) {
        ${LargeButtonsContainer} {
          max-width: 1300px;
          ${orientation === LayoutOrientation.PORTRAIT &&
        css `
            aspect-ratio: 16 / 9;
          `}
        }
      }
    `}
`;
export const Top = styled.div `
  display: flex;
  align-items: flex-end;
`;
export const Bottom = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AspectRatioContainer = styled.div ``;
