import styled, { css } from "styled-components";
export const ControlsContainer = styled.div `
  display: grid;
  grid-template-rows: 1fr minmax(20px, auto) minmax(
      ${(props) => (props.$compact ? 32 : 48)}px,
      auto
    );
  font-size: ${(props) => (props.$compact ? 0.8 : 1)}em;
`;
export const Top = styled.div `
  display: flex;
  align-items: flex-end;
  padding-bottom: 1em;
`;
export const Middle = styled.div ``;
export const Bottom = styled.div ``;
export const Body = styled.div `
  position: relative;
  display: grid;
  grid-template-columns: auto;
  gap: 1em;
  ${({ $isClickableElement }) => $isClickableElement &&
    css `
      cursor: pointer;
    `}
  ${({ $isFullscreen }) => $isFullscreen &&
    css `
      grid-template-rows: 1fr auto 1fr;

      ${Middle} {
        aspect-ratio: 16 / 9;
        max-width: 1300px;
      }
    `};
`;
export const ProgressContainer = styled.div `
  display: flex;
  gap: ${({ $compact }) => ($compact ? "0" : "10px")};
  justify-content: space-between;
  align-items: center;
`;
export const ButtonsContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  pointer-events: auto;
`;
export const ButtonGroup = styled.div `
  justify-self: ${({ $position }) => $position};
  justify-items: ${({ $position }) => $position};
  display: grid;
  grid: repeat(1, 1fr) / auto-flow;
  gap: var(--button-size);
`;
