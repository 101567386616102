import { keys } from "../utils/object-manipulation";
export const space = {
    0: 0,
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 40,
    7: 48,
    8: 56,
    9: 64,
    10: 128,
    11: 256,
    12: 512,
};
export const clampSpaceIndex = (calculation) => {
    const validKeys = keys(space).map((k) => parseInt(k));
    const lowerbound = Math.min(...validKeys);
    const upperbound = Math.max(...validKeys);
    return Math.max(lowerbound, Math.min(upperbound, calculation));
};
