import { WireTrackingService } from "./models/data-models";
import { BaseConfig } from "./remoteConfig";

const config: BaseConfig = {
  AD_IMMUNITY_MAX_TIME: 60,
  PLAYBACK_API_URL: "https://playback2.a2d.tv",
  IMAGE_PROXY_URL: "https://imageproxy.a2d.tv",
  WIRETRACKING_ENDPOINT: "https://tv4.video-tracking.a2d.tv/events",
  WIRETRACKING_ENDPOINT_ANONYMOUS:
    "https://tv4.video-tracking.a2d.tv/anonymous/event",
  CAST_ID: "91309707",
  WIRETRACKING_SERVICE: WireTrackingService.TV4Play,
};

export default config;
