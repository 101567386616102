export interface Track {
  id: string;
  label: string;
  language: string;
  group?: string;
}

export enum AWPAudioTrackChannels {
  MONO = "mono",
  STEREO = "2.0",
  SURROUND = "5.1",
}

export enum AWPTextTrackKind {
  SUBTITLES = "SUBTITLES",
  CC = "CLOSED-CAPTIONS",
  NOTHING = "NOTHING",
}

export interface AWPTextTrack extends Track {
  kind: AWPTextTrackKind;
}

export interface AWPTextTrackSettings {
  currentSubtitles?: string;
  textSize?: number;
}
export interface AWPAudioTrack extends Track {
  channels?: AWPAudioTrackChannels;
}

// string values are used as unique values in case sizes are added or removed
export enum HTMLTextTrackSize {
  XLARGE = "XLARGE",
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
}
