import { AdBreakTrackingEvent, AdTrackingEvent } from "../events";

export enum AdInsertionType {
  ClientSide = "csai",
  ServerSide = "ssai",
  Linear = "linear",
}

export enum AdBreakType {
  Preroll = "preroll",
  Midroll = "midroll",
  Postroll = "postroll",
}

export interface AdServerOptions {
  tags: string;
  shares: string;
  contentForm: string;
  contentId: string;
}

export interface AdMarker {
  position: number;
}

export interface IPauseAd {
  imageSource: string;
  clickthroughUrl?: string;
  impressionUrls: string[];
  clickthroughTrackingUrls: string[];
  errorTrackingUrls: string[];
  trackCollapseUrls: string[];
}
export enum AdVideoVariant {
  NORMAL = "NORMAL",
  SPONSOR = "BUMPER",
  VIGNETTE = "VIGNETTE",
  TRAILER = "TRAILER",
}

export interface IAdMediaFile {
  id?: string;
  fileUrl: string;
  mimeType: string;
  bitrate?: number;
  height?: number;
  width?: number;
}

export type TAdTrackingEvent = {
  id: string;
  url: string;
};

export interface IAdCreative {
  id: string;
  adId?: string;
  type: string;
  duration?: number;
  mediafiles: IAdMediaFile[];
  trackingEvents: {
    [key in AdTrackingEvent]?: string[] | TAdTrackingEvent[];
  };
  clickThroughUrlTemplate?: {
    id: string;
    url: string;
  };
}

export interface IAd {
  id: string;
  universalAdId?: string;
  system?: string;
  sequence?: number;
  title: string;
  variant?: AdVideoVariant;

  creative: IAdCreative;

  errorUrlTemplates?: string[];
  impressionUrlTemplates?: string[];
}

export interface IAdBreak {
  breakType: AdBreakType | string;
  insertionType: AdInsertionType;
  ads: IAd[];
  timeOffset: number;
  // Optional duration, if you want to show the ad break relative time in the progress bar
  duration?: number;
  trackingEvents?: {
    [key in AdBreakTrackingEvent]?: string[];
  };
  empty?: boolean;
}

export const UNKNOWN_AD: IAd = {
  id: "unknown_advert",
  title: "",
  creative: {
    id: "unknown_creative",
    type: "?",
    mediafiles: [],
    trackingEvents: {},
  },
};
