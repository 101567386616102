export type TContext = "core" | "http" | "player" | "engine" | "unknown";

import {
  API_ERROR,
  DRM_ERROR,
  ERROR_CATEGORY,
  MEDIA_ERROR,
  PLAYER_ERROR,
  USER_ERROR,
} from "./ErrorConstants";

export type TErrorCode =
  | API_ERROR
  | DRM_ERROR
  | MEDIA_ERROR
  | PLAYER_ERROR
  | USER_ERROR
  | string;

export enum ErrorLevel {
  CLIENT,
  PLAYER,
  PLATFORM,
  USER,
  UNKNOWN,
}

// Errors are ignored because they should be handled by the client, by showing a relevant message
export const IgnoredErrorsList: Array<TErrorCode> = [
  PLAYER_ERROR.UNSUPPORTED_DEVICE,
  PLAYER_ERROR.AD_BLOCKER,
  USER_ERROR.GEO_LOCATION,
  USER_ERROR.GEO_LOCATION_PORTABILITY,
  USER_ERROR.UNAUTHORIZED,
  USER_ERROR.PROXY_BLOCKED,
  USER_ERROR.NOT_AUTHENTICATED,
  API_ERROR.STREAM_NOT_STARTED,
  API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED,
  API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION,
];

export class AWPError {
  /**
   * Which AWP package caused the error
   * @type {TContext}
   */
  public readonly context: TContext;
  /**
   * Error category
   * @type {string}
   */
  public readonly category: ERROR_CATEGORY;
  /**
   * Error code
   * @type {string}
   */
  public readonly code?: TErrorCode;
  /**
   * Human-readable message
   * @type {string}
   */
  public readonly message: string;
  /**
   * The raw error if any
   * @type {unknown}
   */
  public readonly raw?: unknown;
  /**
   * Any additional error details that may need to propagate
   * @type {Record<string, unknown>}
   */
  public readonly details?: Record<string, unknown>;
  /**
   * Whether the error is fatal (default: true)
   * @type {boolean}
   */
  public readonly fatal?: boolean;
  /**
   * The error level, i.e. owner of the error
   * @type {ErrorLevel}
   */
  public readonly errorLevel: ErrorLevel;

  constructor({
    context,
    code,
    category,
    message,
    raw,
    details,
    fatal = true,
    errorLevel,
  }: AWPError) {
    this.context = context;
    this.message = message;
    this.category = category;
    this.code = code;

    this.raw = raw;
    this.details = details;
    this.fatal = fatal;
    this.errorLevel = errorLevel;
  }
}

/**
 * Ensure that the unknown value caught by try/catch or Promise.catch is an AWPError
 */
export function ensureAWPError(error: unknown): AWPError {
  if (error instanceof AWPError) {
    return error;
  }
  return new AWPError({
    context: "unknown",
    message: `Unhandled error: ${error}`,
    raw: error instanceof Error ? error : undefined,
    category: ERROR_CATEGORY.DEFAULT,
    errorLevel: ErrorLevel.UNKNOWN,
  });
}
