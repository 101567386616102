import { jsx as _jsx } from "react/jsx-runtime";
import AirplaySvg from "../icons/airplay.svg";
import { useControls } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";
export const AirplayButton = () => {
    const controls = useControls();
    if (!controls?.toggleAirplay) {
        return null;
    }
    return (_jsx(Button, { "aria-label": "airplay", onClick: controls?.toggleAirplay, children: _jsx(AirplaySvg, {}) }));
};
export default AirplayButton;
