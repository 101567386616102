import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useLayoutSize } from "../providers/LayoutProvider";
import { PlayerControlButton } from "../styles";
import { LayoutSize } from "../util/device";
const Button = styled(PlayerControlButton) `
  span {
    white-space: nowrap;
  }
  // space between icon and label when both are showing
  svg + span {
    margin-left: 10px;
  }
`;
export const PlayerControlLabelButton = ({ label, autoHideLabel, Icon, ...props }) => {
    const layoutSize = useLayoutSize();
    // default auto hide to true
    if (autoHideLabel !== false &&
        [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize)) {
        label = "";
    }
    return (_jsxs(Button, { ...props, children: [Icon && _jsx(Icon, {}), label && _jsx("span", { children: label })] }));
};
