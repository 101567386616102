import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import CloseIconSvg from "../../icons/CloseIcon.svg";
import { usePlayerState } from "../../providers/CoreProvider";
import { useLayoutSize } from "../../providers/LayoutProvider";
import { useTranslations } from "../../providers/TranslationsProvider";
import { LayoutSize } from "../../util/device";
import { AnimationContainer, CloseButton, Container, Message, MinimizedMessage, } from "./AdShowingIndicator.styles";
const AdShowingIndicator = ({ visible, maximized, }) => {
    const [shownOnce, setShownOnce] = useState(false);
    const { isPauseAd } = usePlayerState();
    const translations = useTranslations();
    const size = useLayoutSize();
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    useEffect(() => {
        if (shownOnce)
            return;
        if (maximized || !isPauseAd)
            return;
        setShownOnce(true);
    }, [maximized, isPauseAd, shownOnce]);
    useEffect(() => {
        if (!isPauseAd)
            setShownOnce(false);
    }, [isPauseAd]);
    return (_jsx(Container, { visible: visible, open: maximized && !shownOnce, isSmallestLayout: isSmallestLayout, children: _jsxs(AnimationContainer, { children: [_jsx("div", {}), !isSmallestLayout && (_jsx(Message, { children: translations("ad_indicator__ad") })), _jsxs(CloseButton, { id: "close-pause-ad-button", "aria-label": "close pause ad", children: [(shownOnce || !maximized || isSmallestLayout) && (_jsx(MinimizedMessage, { children: translations("ad_indicator__ad") })), _jsx(CloseIconSvg, {})] })] }) }));
};
export { AdShowingIndicator };
