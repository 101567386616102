import { jsx as _jsx } from "react/jsx-runtime";
import { DEFAULT_PLAYER_CONFIG } from "@tv4/avod-web-player-common";
import ForwardSvg from "../icons/ForwardIcon.svg";
import { useControls } from "../providers/CoreProvider";
import { PlayerControlButton as Button } from "../styles";
const SEEK_AMOUNT = DEFAULT_PLAYER_CONFIG.seekAmount;
export const SeekForwardButton = () => {
    const controls = useControls();
    const seek = () => {
        controls?.seekForward?.(SEEK_AMOUNT);
    };
    return (_jsx(Button, { "aria-label": `jump ${SEEK_AMOUNT} seconds forward`, onClick: seek, children: _jsx(ForwardSvg, {}) }));
};
export default SeekForwardButton;
