import {
  isAndroid,
  isChromecast,
  isIOS,
  isIpadOS,
} from "@tv4/avod-web-player-device-capabilities";

import { Ad, isMtvOrUutisetService, Service, storage } from "..";
import { AdMarker, IPauseAd } from "../models/ads-models";
import {
  isFotbollskanalen,
  isKoket,
  isMtv,
  isMtvUutiset,
  isMtvUutisetPwa,
  isTv4Se,
} from "./service-type";

// These identifiers are defined in the ad server
function getDCIDForTV4(): string {
  if (isChromecast()) return "b5b11b43-b2e2-42fe-96c3-f6cd322c6383";
  if (isAndroid()) return "740af957-6a1f-4b40-bfe8-f9b23749d88b";
  if (isIOS() || isIpadOS()) return "bfbc3de4-2c4a-406d-9a36-2a5f4da91a32";
  return "d918a1f3-0bcc-438b-a205-b2a87ad0daf6";
}

function getDCIDForMTV(): string {
  if (isAndroid()) return "android_mobile_web";
  if (isIOS() || isIpadOS()) return "ios_web";
  return "computers_html5";
}

export function getDCIDBasedOnService(service: Service): string {
  return isMtvOrUutisetService(service) ? getDCIDForMTV() : getDCIDForTV4();
}

export function getMRMSiteSectionId(service: Service): string {
  if (isFotbollskanalen()) return "fotbollskanalense";
  if (isMtvUutiset()) return "mtvuutiset";
  if (isKoket()) return "koketse";
  if (isTv4Se()) return "tv4se";
  if (isMtv() || service === Service.MTV) return "mtvkatsomo";
  return "tv4playse";
}

function getMRMPlatform() {
  return isMtvUutisetPwa() ? "app" : "web";
}

// config values based on current environment
export function getFreeWheelConfig(service: Service): {
  baseUrl: string;
  networkId: string;
  pauseAdSlot: string;
} {
  // useFreeWheelTestEnv exists for adtech environment testing https://github.com/TV4/freewheel-testing-browser-extension
  const useTest = storage.getSessionData("useFreeWheelTestEnv") === true;
  let environment = useTest ? "80265" : "80276";
  let networkId = useTest ? "524901" : "524918";
  let pauseAdSlot = "TV4_Pausead";
  if (isMtvOrUutisetService(service)) {
    environment = useTest ? "805B8" : "805BA";
    networkId = useTest ? "525752" : "525754";
    pauseAdSlot = useTest ? "MTV FI_Pause2" : "MTV FI_Pause_ad";
  }
  return {
    baseUrl: `https://${environment}.v.fwmrm.net/ad/g/1`,
    networkId,
    pauseAdSlot,
  };
}

export function getMRMProfileDevice(service: Service): string {
  const platform = getMRMPlatform();
  if (isChromecast()) return "ctv_googlecast";
  if (isAndroid()) return `android_${platform}_phone`;
  if (isIOS()) return `ios_${platform}_phone`;
  if (isIpadOS()) return `ios_${platform}_tablet`;
  return `${isMtvOrUutisetService(service) ? "mtv" : "tv4"}_desktop`;
}

export function getMRMSiteSectionDevice(): string {
  let device = "desktop_web";
  const platform = getMRMPlatform();
  if (isChromecast()) device = "chromecast_app";
  if (isAndroid()) device = `mobile_phone_${platform}_android`;
  if (isIOS()) device = `mobile_phone_${platform}_ios`;
  if (isIpadOS()) device = `mobile_tablet_${platform}_ios`;
  return device;
}

export function parsePauseAds(ads: Record<string, any>[]): IPauseAd[] {
  const pauseAds: IPauseAd[] = [];
  ads.forEach((ad) => {
    const creative = ad.creatives[0];
    const pauseAd: IPauseAd = {
      imageSource: creative?.mediaFiles[0]?.fileURL,
      impressionUrls: ad.impressionURLTemplates.map(
        (impressionItem) => impressionItem.url
      ),
      errorTrackingUrls: ad.errorURLTemplates,
      clickthroughUrl: creative?.videoClickThroughURLTemplate?.url,
      clickthroughTrackingUrls: creative?.videoClickTrackingURLTemplates,
      trackCollapseUrls: ad.creatives[0]?.trackingEvents.close,
    };
    pauseAds.push(pauseAd);
  });

  return pauseAds;
}

export function getAdBreakMarkers(ads: Ad[]): AdMarker[] {
  const adMarkers = ads
    .filter((item) => [6, 102].includes(item.categoryId))
    .map((marker) => {
      return { position: marker.timeBegin };
    });
  return adMarkers;
}

export function dateStringToSeconds(dateString: string): number {
  const p = dateString.split(":");
  let s = 0;
  let m = 1;
  while (p.length > 0) {
    s += m * parseInt(p.pop() as string, 10);
    m *= 60;
  }
  return s;
}
