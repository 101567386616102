import { jsx as _jsx } from "react/jsx-runtime";
import { PlaybackState } from "@tv4/avod-web-player-common";
import { useCallback } from "react";
import styled, { css } from "styled-components";
import PauseSvg from "../icons/pause.svg";
import PlaySvg from "../icons/play.svg";
import { useControls, usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { DefaultButton } from "../styles";
import { LayoutSize } from "../util/device";
const Button = styled.button `
  ${DefaultButton}
  transform: translate(-50%, -50%);
  position: absolute;
  ${({ size }) => [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(size)
    ? css `
          width: 5em;
          height: 5em;
        `
    : css `
          width: 7em;
          height: 7em;
        `}

  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6em;
  top: 50%;
  left: 50%;
  svg {
    width: 50%;
    height: 50%;
  }
`;
export const PlaybackButton = ({ onPlayWhenEnded }) => {
    const { playbackState } = usePlayerState();
    const controls = useControls();
    const size = useLayoutSize();
    const togglePlayPause = useCallback(() => {
        if (playbackState === PlaybackState.PAUSED) {
            controls?.play?.();
        }
        else if (playbackState === PlaybackState.ENDED) {
            onPlayWhenEnded?.();
        }
        else {
            controls?.pause?.();
        }
    }, [playbackState, controls, onPlayWhenEnded]);
    return (_jsx(Button, { "aria-label": "start playback", onClick: togglePlayPause, size: size, children: playbackState === PlaybackState.PAUSED ||
            playbackState === PlaybackState.ENDED ? (_jsx(PlaySvg, {})) : (_jsx(PauseSvg, {})) }));
};
export default PlaybackButton;
