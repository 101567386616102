import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AWPTextTrackKind, localPreferences, NO_TEXT_TRACK, } from "@tv4/avod-web-player-common";
import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import { useCallback, useEffect, useMemo } from "react";
import Check from "../../../icons/check.svg";
import { useControls, usePlayerState } from "../../../providers/CoreProvider";
import { useLayoutOrientation, useLayoutSettings, useLayoutSize, useTracksMenuOpen, } from "../../../providers/LayoutProvider";
import { useTranslations } from "../../../providers/TranslationsProvider";
import { LayoutOrientation, LayoutSize } from "../../../util/device";
import TextTrackControls from "../TextTrackControls";
import { TracksButton } from "./TracksButton";
import { CloseIcon, Divider, NoTextTracksAvailable, TrackItem, TrackListContainer, TracksContainer, TracksMenuContainer, TracksWrapper, TrackTitle, } from "./TracksMenu.styles";
import { usePreferredTextSize } from "./usePreferredTextSize";
const getLanguageTranslation = (translations, language) => translations(`language__${language}`);
const getTrackTranslation = (translations, track) => {
    let translation;
    if (track.language) {
        translation = getLanguageTranslation(translations, track.language);
    }
    else if (track.kind === AWPTextTrackKind.NOTHING) {
        translation = translations("language__subtitles_off");
    }
    else {
        translation = track.label;
    }
    return translation || "";
};
const TracksList = ({ tracks, title, activeTrack, onClick, $stackVertically, }) => {
    const translations = useTranslations();
    return (_jsxs(TrackListContainer, { children: [_jsx(TrackTitle, { "$stackVertically": $stackVertically, children: title }), tracks.map((track, i) => {
                const isActive = activeTrack?.id === track.id;
                return (_jsxs(TrackItem, { onClick: () => onClick(track), className: isActive ? "active" : "", children: [_jsx("div", { style: { marginRight: "10px", width: "16px" }, children: isActive && _jsx(Check, { width: "16" }) }), getTrackTranslation(translations, track)] }, track.id + i));
            })] }));
};
const HIDE_TRACKS_MENU_DELAY = 3 * 1000;
let tracksMenuOpenTimeout;
function Tracks({ handleInteraction, closeTracksMenu, displayCover, }) {
    const { activeTextTrack, activeAudioTrack, audioTracks, textTracks, isLive, isFullscreen, } = usePlayerState();
    const controls = useControls();
    const layoutSize = useLayoutSize();
    const layoutOrientation = useLayoutOrientation();
    const translations = useTranslations();
    const handleTextTrackClick = useCallback((textTrack) => {
        controls?.setTextTrack?.(textTrack);
        localPreferences.setPreferredText(textTrack, isLive);
        handleInteraction();
    }, [controls, handleInteraction, isLive]);
    const handleAudioTrackClick = useCallback((audioTrack) => {
        controls?.setAudioTrack?.(audioTrack);
        localPreferences.setPreferredAudio(audioTrack.language);
        handleInteraction();
    }, [controls, handleInteraction]);
    const showTextTracks = textTracks.length > 1;
    const showAudioTracks = audioTracks.length > 1;
    const stackOptionsVertically = layoutSize === LayoutSize.SMALLEST ||
        (isFullscreen && layoutOrientation === LayoutOrientation.PORTRAIT) ||
        (!isFullscreen &&
            layoutOrientation === LayoutOrientation.PORTRAIT &&
            !isDesktopOS());
    return (_jsxs(TracksContainer, { "$displayCover": displayCover, onPointerMove: handleInteraction, children: [_jsx(TracksMenuContainer, { "$displayCover": displayCover, children: _jsx(TracksWrapper, { "$displayCover": displayCover, "$stackVertically": stackOptionsVertically, children: !showTextTracks && !showAudioTracks ? (_jsx(NoTextTracksAvailable, { children: translations("language__no_subtitles") })) : (_jsxs(_Fragment, { children: [showTextTracks && (_jsx(TracksList, { tracks: textTracks, activeTrack: activeTextTrack, title: translations("language__subtitles"), onClick: handleTextTrackClick, "$stackVertically": stackOptionsVertically })), showAudioTracks && (_jsxs(_Fragment, { children: [showTextTracks && (_jsx(Divider, { "$stackVertically": stackOptionsVertically })), _jsx(TracksList, { tracks: audioTracks, activeTrack: activeAudioTrack, title: translations("language__audio"), onClick: handleAudioTrackClick, "$stackVertically": stackOptionsVertically })] })), showTextTracks && (_jsxs(_Fragment, { children: [_jsx(Divider, { "$stackVertically": stackOptionsVertically }), _jsx(TextTrackControls, { "$stackVertically": stackOptionsVertically })] }))] })) }) }), displayCover && _jsx(CloseIcon, { onClick: closeTracksMenu })] }));
}
export const useTracksMenu = () => {
    const { isAd, isPauseAd } = usePlayerState();
    const showTracks = !useLayoutSettings().hideSubtitlesMenu && !isPauseAd && !isAd;
    const { activeTextTrack, activeAudioTrack, audioTracks, textTracks } = usePlayerState();
    const layoutSize = useLayoutSize();
    const { tracksMenuOpen, setTracksMenuOpen } = useTracksMenuOpen();
    const hasTracksToChooseFrom = audioTracks.length > 1 || textTracks.length > 1;
    const translations = useTranslations();
    usePreferredTextSize();
    const activeTextTrackLabel = activeTextTrack?.label || "";
    const activeTextTrackLanguage = activeTextTrack?.language || "";
    const activeAudioTrackLabel = activeAudioTrack?.label || "";
    const activeAudioTrackLanguage = activeAudioTrack?.language || "";
    const textTracksCount = textTracks?.length || 0;
    const audioTracksCount = audioTracks?.length || 0;
    const isSmallLayout = [LayoutSize.SMALL, LayoutSize.SMALLEST].includes(layoutSize);
    const audioAndTextLabel = useMemo(() => {
        if (!hasTracksToChooseFrom)
            return translations("language__no_subtitles");
        const getTextTrackLabel = () => {
            if (activeTextTrackLanguage) {
                return getLanguageTranslation(translations, activeTextTrackLanguage);
            }
            if (textTracksCount > 1) {
                // if there are more than 1 subtitle, but no activeTextTrack then display as no subtitles
                return getTrackTranslation(translations, NO_TEXT_TRACK);
            }
            return activeTextTrackLabel || "";
        };
        const getAudioTrackLabel = () => {
            return activeAudioTrackLanguage &&
                activeAudioTrackLanguage !== "0" &&
                activeAudioTrackLanguage !== "und"
                ? getLanguageTranslation(translations, activeAudioTrackLanguage)
                : activeAudioTrackLabel;
        };
        const textTrackLabel = getTextTrackLabel();
        const audioTrackLabel = getAudioTrackLabel();
        const audioTranslation = translations("language__audio");
        if (!audioTrackLabel || audioTracksCount < 2)
            return textTrackLabel;
        if (!textTrackLabel || textTracksCount < 2)
            return `${audioTranslation}: ${audioTrackLabel}`;
        const separator = textTrackLabel !== "" ? ", " : "";
        return `${textTrackLabel}${separator}${audioTranslation}: ${audioTrackLabel}`;
    }, [
        activeTextTrackLabel,
        activeTextTrackLanguage,
        activeAudioTrackLabel,
        activeAudioTrackLanguage,
        audioTracksCount,
        hasTracksToChooseFrom,
        textTracksCount,
        translations,
    ]);
    const displayCover = isSmallLayout || !isDesktopOS();
    const handleInteraction = useCallback(() => {
        clearTimeout(tracksMenuOpenTimeout);
        if (tracksMenuOpen && !displayCover) {
            tracksMenuOpenTimeout = window.setTimeout(() => setTracksMenuOpen(false), HIDE_TRACKS_MENU_DELAY);
        }
        return () => {
            clearTimeout(tracksMenuOpenTimeout);
        };
    }, [setTracksMenuOpen, tracksMenuOpen, displayCover]);
    // trigger handleInteraction when tracksMenuOpen changes so timeout is started when first opened
    useEffect(handleInteraction, [handleInteraction]);
    // set as closed when unmounted
    useEffect(() => () => setTracksMenuOpen(false), [setTracksMenuOpen]);
    const handleTracksButtonClick = useCallback(() => {
        setTracksMenuOpen(!tracksMenuOpen);
    }, [setTracksMenuOpen, tracksMenuOpen]);
    const closeTracksMenu = useCallback(() => {
        if (tracksMenuOpen) {
            setTracksMenuOpen(false);
        }
    }, [setTracksMenuOpen, tracksMenuOpen]);
    return {
        tracksMenuOpen,
        closeTracksMenu,
        TracksButton: useMemo(() => (props) => showTracks ? (_jsx(TracksButton, { onClick: handleTracksButtonClick, hasTracksToChooseFrom: hasTracksToChooseFrom, label: audioAndTextLabel, ...props })) : null, [
            handleTracksButtonClick,
            hasTracksToChooseFrom,
            audioAndTextLabel,
            showTracks,
        ]),
        TracksMenu: useMemo(() => (props) => showTracks && tracksMenuOpen ? (_jsx(Tracks, { handleInteraction: handleInteraction, closeTracksMenu: closeTracksMenu, displayCover: displayCover, ...props })) : null, [
            handleInteraction,
            closeTracksMenu,
            showTracks,
            tracksMenuOpen,
            displayCover,
        ]),
    };
};
