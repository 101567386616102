import { css } from "styled-components";
import { theme } from "./theme";
const { colors, fonts, fontWeights, fontSizes, lineHeights } = theme;
export const typography = css `
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    color: ${colors.white};
    font-family: ${fonts.body};
    font-weight: ${fontWeights.body};
    font-size: ${fontSizes[3]}px;
    line-height: ${lineHeights.body};
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${fonts.heading};
    font-weight: ${fontWeights.heading};
    line-height: ${lineHeights.heading};
  }
  h1 {
    font-size: ${fontSizes[10]}px;
  }
  h2 {
    font-size: ${fontSizes[9]}px;
  }
  h3 {
    font-size: ${fontSizes[8]}px;
  }
  h4 {
    font-size: ${fontSizes[7]}px;
  }
  h5 {
    font-size: ${fontSizes[6]}px;
  }
  p {
    font-size: ${fontSizes[3]}px;
    line-height: ${lineHeights.body};
  }
  a: {
    color: ${colors.white};
  }
`;
