import { isMobile } from "@tv4/avod-web-player-device-capabilities";

export function getImageProxyUrl(url?: string): string | undefined {
  if (!url) {
    return;
  }
  const width = isMobile() ? "600" : "900";
  try {
    const imageUrl = new URL(url);
    if (imageUrl.hostname === "imageproxy.a2d.tv") {
      // Avoid proxying urls multiple times, instead override the width param
      imageUrl.searchParams.set("width", width);
      return imageUrl.toString();
    }
  } catch (error) {
    // Ignore invalid URL for now
    console.warn("Invalid image url", error);
    return;
  }

  return `https://imageproxy.a2d.tv/?source=${encodeURIComponent(url)}&width=${width}`;
}
