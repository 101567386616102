import { useCallback, useContext, useEffect } from "react";
import { LayoutContext } from "../providers/LayoutProvider";
const interactionStartCallbacks = [];
const interactionEndCallbacks = [];
function addCallback(callbacks, callback) {
    if (callback) {
        callbacks.unshift(callback);
    }
}
function removeCallback(callbacks, callback) {
    let index = -1;
    if (callback) {
        index = callbacks.indexOf(callback);
    }
    if (index !== -1) {
        callbacks.splice(index, 1);
    }
}
function triggerCallbacks(callbacks) {
    let i = callbacks.length;
    while (i--) {
        callbacks[i]();
    }
}
export default function useAutoHideUI({ onInteractionStart, onInteractionEnd, } = {}) {
    const { interacting, handleInteractionStart, handleInteractionEnd } = useContext(LayoutContext);
    const interactionStart = useCallback(() => {
        handleInteractionStart();
        triggerCallbacks(interactionStartCallbacks);
    }, [handleInteractionStart]);
    const interactionEnd = useCallback(() => {
        handleInteractionEnd();
        if (!interacting()) {
            triggerCallbacks(interactionEndCallbacks);
        }
    }, [interacting, handleInteractionEnd]);
    const handleInteraction = useCallback(() => {
        interactionStart();
        interactionEnd();
    }, [interactionStart, interactionEnd]);
    useEffect(() => {
        addCallback(interactionEndCallbacks, onInteractionEnd);
        addCallback(interactionStartCallbacks, onInteractionStart);
        if (onInteractionEnd || onInteractionStart) {
            return () => {
                removeCallback(interactionEndCallbacks, onInteractionEnd);
                removeCallback(interactionStartCallbacks, onInteractionStart);
            };
        }
    }, [onInteractionStart, onInteractionEnd]);
    return {
        interacting,
        handleInteraction,
        handleInteractionStart: interactionStart,
        handleInteractionEnd: interactionEnd,
    };
}
