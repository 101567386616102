/**
 * Constants related to HTML text track sizes
 */

import { HTMLTextTrackSize } from "../models/track-models";

export const defaultTextSizes = {
  [HTMLTextTrackSize.XLARGE]: {
    size: 5.5,
    previewSize: 23,
    previewLineHeight: 32,
  },
  [HTMLTextTrackSize.LARGE]: {
    size: 4.5,
    previewSize: 16,
    previewLineHeight: 22,
  },
  [HTMLTextTrackSize.MEDIUM]: {
    size: 3.5,
    previewSize: 14,
    previewLineHeight: 20,
  },
  [HTMLTextTrackSize.SMALL]: {
    size: 2.25,
    previewSize: 12,
    previewLineHeight: 16,
  },
};
