import { jsx as _jsx } from "react/jsx-runtime";
import { getRemoteConfigValue, } from "@tv4/avod-web-player-common";
import { getThumbnails } from "@tv4/avod-web-player-thumbnail-vtt-parser";
import { createContext, useContext, useEffect, useState } from "react";
import { LayoutSize } from "../util/device";
import { useLayoutSize } from "./LayoutProvider";
const ThumbnailsContext = createContext({
    thumbnails: {},
    useImageProxy: false,
});
export function ThumbnailsProvider({ thumbnails = {}, useImageProxy, children, }) {
    return (_jsx(ThumbnailsContext.Provider, { value: { thumbnails, useImageProxy }, children: children }));
}
export const useThumbnailCues = () => {
    const imageProxyUrl = getRemoteConfigValue("IMAGE_PROXY_URL");
    const { thumbnails, useImageProxy } = useContext(ThumbnailsContext);
    const size = useLayoutSize();
    const [thumbnailSize, setThumbnailSize] = useState();
    const [cues, setCues] = useState([]);
    useEffect(() => {
        switch (size) {
            case LayoutSize.LARGE:
            case LayoutSize.MEDIUM:
                setThumbnailSize("desktop");
                break;
            default:
                setThumbnailSize("mobile");
                break;
        }
    }, [size, thumbnails]);
    useEffect(() => {
        if (!thumbnailSize)
            return;
        const thumbnailsUrl = thumbnails[thumbnailSize]?.url;
        if (!thumbnailsUrl) {
            setCues([]);
            return;
        }
        let cancelled = false;
        getThumbnails(thumbnailsUrl)
            .then((thumbnailCues) => {
            if (!cancelled)
                setCues(thumbnailCues.map((cue) => {
                    let imageUrl = cue.imageUrl;
                    if (useImageProxy) {
                        const proxyUrl = new URL(imageProxyUrl);
                        proxyUrl.searchParams.append("source", imageUrl);
                        imageUrl = proxyUrl.toString();
                    }
                    return {
                        ...cue,
                        imageUrl,
                    };
                }));
        })
            .catch(() => {
            if (!cancelled)
                setCues([]);
        });
        return () => {
            cancelled = true;
        };
    }, [thumbnails, useImageProxy, imageProxyUrl, thumbnailSize]);
    return cues;
};
