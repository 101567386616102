import { CoreEvents, DEFAULT_PLAYER_STATE, } from "@tv4/avod-web-player-common";
import { useEffect, useState } from "react";
const useCore = (core) => {
    const [playerState, setPlayerState] = useState(DEFAULT_PLAYER_STATE);
    const [adBreaks, setAdBreaks] = useState([]);
    const [currentAdBreak, setCurrentAdBreak] = useState();
    const [currentAd, setCurrentAd] = useState();
    const [controls, setControls] = useState(null);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState(core.getOptions());
    const [chromeCastManager, setChromeCastManager] = useState(core.getChromeCastManager());
    useEffect(() => {
        core.on(CoreEvents.STATE_CHANGED, ({ state }) => {
            setPlayerState(state);
        });
        core.on(CoreEvents.RESET, () => {
            setCurrentAdBreak(undefined);
            setCurrentAd(undefined);
        });
        core.on(CoreEvents.LOADED_PLAYBACK, ({ adBreaks: adBreakList }) => {
            setControls(core.getControls());
            setAdBreaks(adBreakList);
        });
        core.on(CoreEvents.ERROR, ({ error: e }) => {
            setError(e);
        });
        core.on(CoreEvents.LOAD_PLAYBACK_ERROR, ({ error: e }) => {
            setError(e);
        });
        core.on(CoreEvents.CORE_OPTIONS_CHANGED, () => {
            setOptions(core.getOptions());
        });
        core.on(CoreEvents.CHROMECAST_ADMARKERS_LOADED, ({ adBreaks: adBreakList }) => {
            setAdBreaks(adBreakList);
        });
        core.on(CoreEvents.AD_START, ({ ad }) => {
            setCurrentAd(ad);
        });
        core.on(CoreEvents.AD_RESUME, ({ ad }) => {
            setCurrentAd(ad);
        });
        core.on(CoreEvents.BREAK_START, ({ adBreak }) => {
            setCurrentAdBreak(adBreak);
        });
        core.on(CoreEvents.BREAK_END, () => {
            setCurrentAdBreak(undefined);
            setCurrentAd(undefined);
        });
        core.on(CoreEvents.CHROMECAST_BREAK_START, ({ adBreak }) => {
            setCurrentAdBreak(adBreak);
        });
        core.on(CoreEvents.CHROMECAST_AD_START, ({ ad }) => {
            setCurrentAd(ad);
        });
        core.on(CoreEvents.CHROMECAST_BREAK_END, () => {
            setCurrentAdBreak(undefined);
            setCurrentAd(undefined);
        });
        core.on(CoreEvents.CHROMECAST_SENDER_LOADED, () => {
            setChromeCastManager(core.getChromeCastManager());
        });
    }, [core]);
    useEffect(() => {
        if (!chromeCastManager)
            return;
        chromeCastManager.on(CoreEvents.CHROMECAST_CONNECTED, () => {
            setControls(chromeCastManager.getControls());
        });
        chromeCastManager.on(CoreEvents.CHROMECAST_DISCONNECTED, () => {
            setControls(core.getControls());
        });
    }, [chromeCastManager, core]);
    return {
        controls,
        playerState,
        adBreaks,
        currentAdBreak,
        currentAd,
        chromeCastManager,
        options,
        error,
    };
};
export default useCore;
