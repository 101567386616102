import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AdInsertionType } from "@tv4/avod-web-player-common";
import { getRemainingAdImmunity, hasAdImmunity, version, } from "@tv4/avod-web-player-core";
import { useEffect, useRef, useState } from "react";
import { useDebugInfo, usePlayerState } from "../../providers/CoreProvider";
import { useMetadata } from "../../providers/MetadataProvider";
import { OverlayContainer, Table, TableHeading, TableValue, Title, } from "./DebugOverlay.styles";
const useInterval = (callback, delay) => {
    const callbackRef = useRef(callback);
    const intervalIdRef = useRef(-1);
    useEffect(() => {
        // always keep reference to callback from last render
        callbackRef.current = callback;
    }, [callback]);
    useEffect(() => {
        if (delay !== -1) {
            intervalIdRef.current = window.setInterval(() => callbackRef.current(), delay * 1000);
        }
        // stop interval when unmounted or when dependencies change
        return () => {
            if (intervalIdRef.current !== -1) {
                window.clearInterval(intervalIdRef.current);
                intervalIdRef.current = -1;
            }
        };
    }, [delay]);
};
const DebugOverlay = () => {
    const state = usePlayerState();
    const { asset } = useMetadata();
    const debugInfo = useDebugInfo();
    const [adImmunityRemaining, setAdImmunityRemaining] = useState(0);
    const { bitrates = [], engineName, engineVersion, } = debugInfo?.mediaEngine?.getDebugInfo() ?? {};
    const playbackDebug = debugInfo?.playbackController?.getDebugInfo();
    const adImmunityMaxTime = playbackDebug?.data?.adImmunityMaxTime || 0;
    const immune = asset && hasAdImmunity(asset.assetId, adImmunityMaxTime);
    useInterval(() => {
        setAdImmunityRemaining(asset ? getRemainingAdImmunity(asset.assetId, adImmunityMaxTime) : 0);
    }, immune ? 1 : -1);
    return (_jsxs(OverlayContainer, { children: [_jsx(Title, { children: "Debug Overlay" }), _jsx(Table, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx(TableHeading, { children: "Player version" }), _jsx(TableValue, { children: version })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Media engine" }), _jsx(TableValue, { children: engineVersion
                                        ? `${engineName} (${engineVersion})`
                                        : (engineName ?? "") })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Playback type" }), _jsx(TableValue, { children: playbackDebug?.type })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Asset (Vimond) ID" }), _jsx(TableValue, { children: asset?.vimondId })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Current bitrate" }), _jsx(TableValue, { children: state.currentBitrate })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Available bitrates" }), _jsx(TableValue, { children: _jsx(_Fragment, { children: bitrates.map((item) => JSON.stringify(item)).join("\n") }) })] }), playbackDebug?.type ===
                            AdInsertionType.ClientSide && (_jsxs(_Fragment, { children: [_jsxs("tr", { children: [_jsx(TableHeading, { children: "Ad immunity period" }), _jsx(TableValue, { children: adImmunityMaxTime })] }), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Ad immunity" }), _jsx(TableValue, { children: (String(immune) + ",  ").slice(0, 7) +
                                                (" " + Math.round(immune ? adImmunityRemaining : 0)).slice(-2) +
                                                " / " +
                                                adImmunityMaxTime +
                                                " seconds remaining" })] })] })), _jsxs("tr", { children: [_jsx(TableHeading, { children: "Text tracks" }), _jsx(TableValue, { children: _jsx(_Fragment, { children: state.textTracks
                                            .map((item) => JSON.stringify(item))
                                            .join("\n") }) })] })] }) })] }));
};
export { DebugOverlay };
